import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import moment from "moment";

//style
import Styles from '../style'
import ExampleImg from '../../../assets/images/placeholder.png';

// components
import { AutoComplete, Switch, TextField, ImageUpload, Dialog } from "../../../components";

// reducer
import {
    updateUnits, updateUnitCount, updateUnitDetails, deleteUnit, deleteUnitDetail,
    addNewUnit, updateUnitPhotos, deleteUnitPhotos, updatePropertyUnitsStatusRequest, addSubUnit
} from "../../../redux/reducer/propertiesReducer";
import { successMessage, errorMessage } from "../../../redux/reducer/uiReducer";

// helpers
import { affordabilityLevelOptions, getImage, gets3URL, isNumberValid, isRequired, unitTypesOptions } from "../../../helpers";
import { Photo } from "@mui/icons-material";
import { GalleryIcon } from "../../../assets/svg";

const UnitsRents = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    // redux
    const { masterUnits } = useSelector(s => s.global)
    const { currentProperty: { general, general : propertiesDetail, units }, unitPhotos, changedFields } = useSelector(s => (s.properties))
    const { loggedUser } = useSelector(s => (s.profile));

    // state
    const [gallery, openGallery] = useState(false);
    const [deleteUnitPopup, setDeleteUnitPopup] = useState(false);
    const [deleteUnitDetailPopup, setDeleteUnitDetailPopup] = useState(false);
    const [photosCount, setPhotosCount] = useState(0);
    const [isCountSet, setIsCountSet] = useState(false);
    const [editAlert, setEditAlert] = useState(false);

    const approvalStatus = useSelector((state) => state.properties.currentProperty.general.approval_status);
    console.log('Approval Status:', approvalStatus, 'Type:', typeof approvalStatus);

    // props
    const { unitsValidate, isDisabled } = props;

    // variables
    const isPropertyManager = loggedUser.user_group_id === 2
    const isCityStaff = loggedUser.user_group_id === 3

    // unit changes
    const handleUnitChange = (value, index) => {
        if (isLotteryDateUpdated()) {
            setEditAlert(true);
            return;
        }
        let tmpUnits = [...units]
        let tmpUnitDetailPhotos = { ...unitPhotos }
        tmpUnits[index] = { ...tmpUnits[index], ...value }
        if (value.hasOwnProperty("unit_count")) {
            if (value.unit_count > 25) {
                dispatch(errorMessage("Please enter unit count less than 25"));
            } else {
                dispatch(updateUnitCount({ data: tmpUnits, index: index, photos: tmpUnitDetailPhotos }))
            }
        } else {
            dispatch(updateUnits(tmpUnits))
        }
    }

    // update unit Details
    const handleUnitDetailsChange = (value, unitIndex, detailIndex) => {
        if (isLotteryDateUpdated()) {
            setEditAlert(true);
            return
        }
        dispatch(updateUnitDetails({ data: value, unitIndex, detailIndex }))
    }

    const getHousehold = (unit_type) => {
        switch (unit_type) {
            case 1:
                return { min_household_size: 1, max_household_size: 2 }
            case 2:
                return { min_household_size: 2, max_household_size: 3 }
            case 3:
                return { min_household_size: 4, max_household_size: 5 }
            case 4:
                return { min_household_size: 5, max_household_size: 6 }
            case 5:
                return { min_household_size: 8, max_household_size: 10 }
            case 6:
                return { min_household_size: 10, max_household_size: 12 }
            case 7:
                return { min_household_size: 0, max_household_size: 1 }
            default:
                return { min_household_size: '', max_household_size: '' }
        }
    }

    const getAMI = (aff_level) => {
        switch (aff_level) {
            case 1:
                return { min_ami: "0%", max_ami: "30%" }
            case 2:
                return { min_ami: "30%", max_ami: "50%" }
            case 3:
                return { min_ami: "50%", max_ami: "80%" }
            case 4:
                return { min_ami: "80%", max_ami: "120%" }
            default:
                return { min_ami: '', max_ami: '' }
        }
    }

    const getUnitPhotoCount = (unitPhotos, index, itemIndex) => {
        const photos = unitPhotos && unitPhotos[`${index}_${itemIndex}`] ? unitPhotos[`${index}_${itemIndex}`] : []
        const photoCount = photos.length || 0
        return photoCount
    }

    const getUnitPhoto = (unitPhotos, index, itemIndex) => {
        const photos = unitPhotos && unitPhotos[`${index}_${itemIndex}`] ? unitPhotos[`${index}_${itemIndex}`] : []
        if (!isCountSet && photos.length > 0) {
            setPhotosCount(photos.length);
            setIsCountSet(true);
        }
        if (photos && photos[0]) {
            return typeof photos[0] === "object" && photos[0].photo ? gets3URL(photos[0].photo) : getImage(photos[0])
        } else {
            return ExampleImg
        }
    }

    // showing only the unselected unity type in the dropdown
    const getUnitSelections = (unit_type) => {
        const allUnitIds = units.map(m => { return m.unit_type })
        const allUnitIds1 = allUnitIds.filter(f => (f !== unit_type))
        return unit_type ? masterUnits.filter(f => (!allUnitIds1.includes(f.id))) : masterUnits.filter(f => (!allUnitIds.includes(f.id)))
    }

    const isFieldDisabled = (unit, field) => {
        const isExistingUnit = !!unit.id; // True if the unit has an ID, indicating it is an existing unit
        const editableFields = ['unit_type','status', 'min_rent_amt', 'max_rent_amt', 'lease_length', 'household_min_income', 'household_max_income'];
        
        return (
            approvalStatus === 1 || 
            isCityStaff || 
            !(approvalStatus === 0 || approvalStatus === 2 || approvalStatus === 3) || 
            !isPropertyManager || 
            propertiesDetail.lottery_count < 0 || 
            (isExistingUnit && !editableFields.includes(field)) 
        );
    };

    const isLotteryDateUpdated = () => {
        if(changedFields.length > 0 && changedFields.includes('Lottery Date') && approvalStatus !== undefined) {
            return true;
        }
        return false;
    }

    const isFieldDisabled1 = 
    approvalStatus === 1 || 
    !(approvalStatus === 0 || approvalStatus === 2 || approvalStatus === 3) || 
	propertiesDetail.lottery_count < 0;

    const isFieldDisabled2 = 
    approvalStatus === 1 || 
    isCityStaff || 
    !(approvalStatus === 0 || approvalStatus === 2 || approvalStatus === 3) || 
    !isPropertyManager ||
	propertiesDetail.lottery_count < 0 

    return (
        <>
            <Grid className={"unitsRents"}>
                <Grid container justifyContent={"space-between"} alignItems="center">
                    <Typography variant="body1">Configure Unit & Rent Details</Typography>
                    {!isDisabled && 
                    <Button 
                        onClick={() => {
                            if (isLotteryDateUpdated()) {
                                setEditAlert(true);
                            } else {
                            dispatch(addNewUnit())
                            }
                        }} 
                        type="text" 
                        size="small" 
                        className="fs-14" 
                        aria-label={units && units.length ? "+ Add another unit type" : "+ Add unit type"}>
                        {units && units.length ? "+ Add another unit type" : "+ Add unit type"}
                    </Button>}
                </Grid>
                <Grid item xs={12} className="pt-2 pb-3">
                    {units && units.map((item, index) => (
                        <Accordion key={`accord-iopn-${index}`} expanded={item.accordion} onChange={() => { handleUnitChange({ accordion: !item.accordion }, index) }}>
                            <AccordionSummary
                                expandIcon={<Tooltip arrow title="Unit Details"><ExpandMoreIcon sx={{ color: '#343487' }} /></Tooltip>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid container spacing={2} justifyContent={"space-between"} wrap="nowrap" className="accordionHeader" onClick={(e) => e.stopPropagation()}>
                                    <Grid item sx={{ flex: '1' }}>
                                        <Typography variant="caption" color="textSecondary" noWrap>UNIT TYPE</Typography>
                                        <AutoComplete
                                            id={"unit_type"}
                                            name={"unit_type"}
                                            aria-label="Select Unit type"
                                            value={{ id: item.unit_type }}
                                            options={getUnitSelections(item.unit_type)}
                                            display={"name"}
                                            disabled={isDisabled || item.unit_type}
                                            className={`inlineEdit sm ${item.unit_type ? '' : 'nodata'}`}
                                            handleChange={(e) => {
                                                // validation to remove duplicate unit type adding
                                                const isFound = units.find((u, indx) => (indx !== index && e && e.value && u.unit_type == e.value.id))
                                                if (isFound) {
                                                    dispatch(errorMessage("Unit Type already available"));
                                                    handleUnitChange({ [e.name]: "" }, index)
                                                } else {
                                                    handleUnitChange({ [e.name]: e.value.id, ...getHousehold(e.value.id) }, index)
                                                }
                                            }}
                                            validation={isRequired(unitsValidate, item.unit_type)}
                                        />
                                    </Grid>
                                    <Grid item sx={{ flex: '1' }}>
                                        <Typography variant="caption" color="textSecondary" noWrap>UNIT COUNT</Typography>
                                        <TextField
                                            id={"unit_count"}
                                            name={"unit_count"}
                                            aria-label="Select unit count"
                                            value={item.unit_count}
                                            className={`inlineEdit sm ${item.unit_count ? '' : 'nodata'}`}
                                            type={"number"}
                                            disabled={true}
                                            handleChange={(e) => { handleUnitChange({ [e.name]: e.value }, index) }}
                                        />
                                    </Grid>
                                    <Grid item sx={{ flex: '1' }}>
                                        <Typography variant="caption" color="textSecondary" noWrap>HOUSEHOLD SIZE</Typography>
                                        <Grid container flexWrap={"nowrap"} className="twoField">
                                            <Grid sx={{ '& input': { width: `${item.min_household_size ? `${item.min_household_size.toString().length + 1}ch` : '2ch'} ` } }}>
                                                <TextField
                                                    id={"min_household_size"}
                                                    name={"min_household_size"}
                                                    aria-label="Enter minimum household size"
                                                    value={item.min_household_size}
                                                    className={`inlineEdit sm ${item.min_household_size ? '' : 'nodata'}`}
                                                    type={"number"}
                                                    disabled={true}
                                                    handleChange={(e) => { handleUnitChange({ [e.name]: e.value }, index) }}
                                                />
                                            </Grid>
                                            <span style={{ padding: '8px 5px' }}>-</span>
                                            <Grid className="pl-1" sx={{ '& input': { width: `${item.max_household_size ? `${item.max_household_size.toString().length + 1}ch` : '2ch'} ` } }}>
                                                <TextField
                                                    id={"max_household_size"}
                                                    name={"max_household_size"}
                                                    aria-label="Enter maximum household size"
                                                    value={item.max_household_size}
                                                    className={`inlineEdit sm ${item.max_household_size ? '' : 'nodata'}`}
                                                    type={"text"}
                                                    disabled={true}
                                                    handleChange={(e) => { handleUnitChange({ [e.name]: e.value }, index) }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {!isDisabled && 
                                    <Tooltip title={"Delete"} arrow>
                                        <IconButton 
                                            disableRipple 
                                            onClick={() => { 
                                                if (isLotteryDateUpdated()) {
                                                    setEditAlert(true);
                                                } else {
                                                    setDeleteUnitPopup({ index: index, units: units }) }}
                                                }
                                            sx={{ height: 'auto', marginTop: '15px', marginLeft: '10px' }} aria-label="Delete">
                                                <DeleteOutlineIcon sx={{ color: '#343487' }} />
                                        </IconButton>
                                    </Tooltip>}
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer className="unitsFields">
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Active /Inactive</TableCell>
                                                <TableCell>Photos</TableCell>
                                                <TableCell sx={{ width: '10%', minWidth: '74px' }}>Unit No.</TableCell>
                                                <TableCell sx={{ width: '15%', minWidth: '160px' }}>Affordability Level</TableCell>
                                                {general.ami_rmi === 1 ? <TableCell sx={{ width: '12%', minWidth: '135px' }}>AMI%</TableCell> : <TableCell sx={{ width: '12%', minWidth: '135px' }}>RMI%</TableCell>}
                                                <TableCell sx={{ width: '10%', minWidth: '112px' }}>Monthly Rent Range</TableCell>
                                                <TableCell sx={{ width: '10%', minWidth: '90px' }}>Lease Length</TableCell>
                                                <TableCell sx={{ width: '12%', minWidth: '80px' }}>Sq. Ft</TableCell>
                                                <TableCell sx={{ width: '18%', minWidth: '170px' }}>Annual Household Income</TableCell>
                                                {!isDisabled && <TableCell sx={{ width: '8%', minWidth: '52px' }}>Actions</TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {item && item.unitDetails && item.unitDetails.map((itemDetail, itemIndex) => (
                                                <TableRow
                                                    key={`sdf-kwey-${itemIndex}`}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell>
                                                        <Tooltip title={!itemDetail.status ? "Inactive" : "Active"} arrow>
                                                            <Grid>
                                                                <Switch
                                                                    checked={itemDetail.status}
                                                                    disabled={isCityStaff ? false : isFieldDisabled1}
                                                                    onChange={() => {
                                                                        if (isCityStaff) {
                                                                            dispatch(updatePropertyUnitsStatusRequest({ unit_id: item.id, unit_detail_id: itemDetail.id, property_id: general.id, status: !itemDetail.status }))
                                                                        }
                                                                        handleUnitDetailsChange({ status: !itemDetail.status }, index, itemIndex)
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell 
                                                        onClick={() => {
                                                            if (isLotteryDateUpdated()) {
                                                                setEditAlert(true);
                                                            } else {
                                                                openGallery({ index, itemIndex }) 
                                                            }
                                                        }}
                                                        className={`${photosCount && photosCount > 1 ? "unitmoreImage" : ''}`}
                                                    >
                                                        {(parseInt(photosCount) > 1) && <Grid className="photosCount"><GalleryIcon />{getUnitPhotoCount(unitPhotos, index, itemIndex)}</Grid>}
                                                        <Tooltip arrow title={`${isDisabled ? "View Photos" : "Add Photos"}`}>
                                                            <img className="tableImg" src={getUnitPhoto(unitPhotos, index, itemIndex)} alt="Unit photos" />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell sx={{ '& input': { width: `${itemDetail.unit_no ? `${itemDetail.unit_no.toString().length + 1}ch` : 'inherit'} ` } }}>
                                                        <TextField
                                                            id={"unit_no"}
                                                            name={"unit_no"}
                                                            aria-label="Enter unit number"
                                                            value={itemDetail.unit_no}
                                                            disabled={propertiesDetail?.lottery_count > 0 && itemDetail.id} 
                                                            className={`inlineEdit sm ${itemDetail.unit_no ? '' : 'nodata'}`}
                                                            handleChange={(e) => { handleUnitDetailsChange({ [e.name]: e.value }, index, itemIndex) }}
                                                            validation={isRequired(unitsValidate, itemDetail.unit_no)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <AutoComplete
                                                            id={"affordability_level"}
                                                            name={"affordability_level"}
                                                            aria-label="Select affordability level"
                                                            value={{ id: itemDetail.affordability_level }}
                                                            options={affordabilityLevelOptions}
                                                            disabled={propertiesDetail?.lottery_count > 0 && itemDetail.id}   
                                                            className={`inlineEdit sm ${itemDetail.affordability_level ? '' : 'nodata'}`}
                                                            handleChange={(e) => { handleUnitDetailsChange({ [e.name]: e.value.id, ...getAMI(e.value.id) }, index, itemIndex) }}
                                                            validation={isRequired(unitsValidate, itemDetail.affordability_level)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className="rangeValues">
                                                            <Grid sx={{ '& input': { width: `${itemDetail.min_ami ? `${itemDetail.min_ami.toString().length + 1}ch` : 'inherit'} ` } }}>
                                                                <TextField
                                                                    id={"min_ami"}
                                                                    name={"min_ami"}
                                                                    aria-label="Enter minimum ami"
                                                                    value={itemDetail.min_ami}
                                                                    className={`inlineEdit sm ${itemDetail.min_ami ? '' : 'nodata'}`}
                                                                    type={"text"}
                                                                    disabled={true}
                                                                    handleChange={(e) => { handleUnitDetailsChange({ [e.name]: e.value }, index, itemIndex) }}
                                                                />
                                                            </Grid>
                                                            <span style={{ padding: '8px 5px' }}>-</span>
                                                            <Grid sx={{ '& input': { width: `${itemDetail.max_ami ? `${itemDetail.max_ami.toString().length + 1}ch` : 'inherit'} ` } }}>
                                                                <TextField
                                                                    id={"max_ami"}
                                                                    name={"max_ami"}
                                                                    aria-label="Enter maximum ami"
                                                                    value={itemDetail.max_ami}
                                                                    disabled={true}
                                                                    className={`inlineEdit sm ${itemDetail.max_ami ? '' : 'nodata'}`}
                                                                    type={"text"}
                                                                    handleChange={(e) => { handleUnitDetailsChange({ [e.name]: e.value }, index, itemIndex) }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className="rangeValues">
                                                            <Grid sx={{ '& input': { width: `${itemDetail.min_rent_amt ? `${itemDetail.min_rent_amt.toString().length + 1}ch` : 'inherit'} ` } }}>
                                                                <TextField
                                                                    id={"min_rent_amt"}
                                                                    name={"min_rent_amt"}
                                                                    aria-label="Enter minimum rent amount"
                                                                    value={itemDetail.min_rent_amt}
                                                                    className={`inlineEdit sm adorementBlock ${itemDetail.min_rent_amt ? '' : 'nodata'}`}
                                                                    type={"text"}
                                                                    disabled={isDisabled}
                                                                    startIcon={"$"}
                                                                    handleChange={(e) => { handleUnitDetailsChange({ [e.name]: e.value }, index, itemIndex) }}
                                                                    validation={isRequired(unitsValidate, itemDetail.min_rent_amt) ? isRequired(unitsValidate, itemDetail.min_rent_amt) : unitsValidate && Number(itemDetail.min_rent_amt) >= Number(itemDetail.max_rent_amt) ? "Invalid rent range" : null}
                                                                />
                                                            </Grid>
                                                            <span style={{ padding: '8px 5px' }}>-</span>
                                                            <Grid sx={{ '& input': { width: `${itemDetail.max_rent_amt ? `${itemDetail.max_rent_amt.toString().length + 1}ch` : 'inherit'} ` } }}>
                                                                <TextField
                                                                    id={"max_rent_amt"}
                                                                    name={"max_rent_amt"}
                                                                    aria-label="Enter maximum rent amount"
                                                                    value={itemDetail.max_rent_amt}
                                                                    disabled={isDisabled}
                                                                    className={`inlineEdit sm adorementBlock ${itemDetail.max_rent_amt ? '' : 'nodata'}`}
                                                                    type={"text"}
                                                                    startIcon={"$"}
                                                                    handleChange={(e) => { handleUnitDetailsChange({ [e.name]: e.value }, index, itemIndex) }}
                                                                    validation={isRequired(unitsValidate, itemDetail.max_rent_amt) ? isRequired(unitsValidate, itemDetail.max_rent_amt) : unitsValidate && Number(itemDetail.min_rent_amt) >= Number(itemDetail.max_rent_amt) ? "Invalid rent range" : null}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell sx={{ '& input': { width: `${itemDetail.lease_length ? `${itemDetail.lease_length.toString().length}ch` : 'inherit'}` } }}>
                                                        <TextField
                                                            id={"lease_length"}
                                                            name={"lease_length"}
                                                            aria-label="Enter lease length"
                                                            value={itemDetail.lease_length}
                                                            className={`inlineEdit sm adormentVisible ${itemDetail.lease_length ? '' : 'nodata'}`}
                                                            type={"text"}
                                                            disabled={isDisabled}
                                                            handleChange={(e) => { handleUnitDetailsChange({ [e.name]: e.value }, index, itemIndex) }}
                                                            validation={isNumberValid(unitsValidate, itemDetail.lease_length)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        Years
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{ '& input': { width: `${itemDetail.sqft ? `${itemDetail.sqft.toString().length}ch` : 'inherit'}` } }}>
                                                        <TextField
                                                            id={"sqft"}
                                                            name={"sqft"}
                                                            aria-label="Enter Square feet"
                                                            value={itemDetail.sqft}
                                                            className={`inlineEdit sm adormentVisible ${itemDetail.sqft ? '' : 'nodata'}`}
                                                            type={"text"}
                                                            disabled={propertiesDetail?.lottery_count > 0 && itemDetail.id} 
                                                            handleChange={(e) => { handleUnitDetailsChange({ [e.name]: e.value }, index, itemIndex) }}
                                                            validation={isNumberValid(unitsValidate, itemDetail.sqft)}
                                                            InputProps={itemDetail.sqft ? {
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        SF
                                                                    </InputAdornment>
                                                                ),
                                                            }: ""}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid className="rangeValues">
                                                            <Grid sx={{ '& input': { width: `${itemDetail.household_min_income ? `${itemDetail.household_min_income.toString().length}ch` : 'inherit'}` } }}>
                                                                <TextField
                                                                    id={"household_min_income"}
                                                                    name={"household_min_income"}
                                                                    aria-label="Enter household minimum income"
                                                                    value={itemDetail.household_min_income}
                                                                    className={`inlineEdit adorementBlock sm ${itemDetail.household_min_income ? '' : 'nodata'}`}
                                                                    type={"text"}
                                                                    disabled={isDisabled}
                                                                    handleChange={(e) => { handleUnitDetailsChange({ [e.name]: e.value }, index, itemIndex) }}
                                                                    startIcon={"$"}
                                                                    validation={isNumberValid(unitsValidate, itemDetail.household_min_income) ? isNumberValid(unitsValidate, itemDetail.household_min_income) : unitsValidate && Number(itemDetail.household_min_income) >= Number(itemDetail.household_max_income) ? "Invalid income range" : null}
                                                                />
                                                            </Grid>
                                                            <span style={{ padding: '8px 5px' }}>-</span>
                                                            <Grid sx={{ '& input': { width: `${itemDetail.household_max_income ? `${itemDetail.household_max_income.toString().length}ch` : 'inherit'}` } }}>
                                                                <TextField
                                                                    id={"household_max_income"}
                                                                    name={"household_max_income"}
                                                                    aria-label="Enter household maximum income"
                                                                    value={itemDetail.household_max_income}
                                                                    className={`inlineEdit adorementBlock sm ${itemDetail.household_max_income ? '' : 'nodata'}`}
                                                                    type={"text"}
                                                                    disabled={isDisabled}
                                                                    handleChange={(e) => { handleUnitDetailsChange({ [e.name]: e.value }, index, itemIndex) }}
                                                                    startIcon={"$"}
                                                                    validation={isNumberValid(unitsValidate, itemDetail.household_max_income) ? isNumberValid(unitsValidate, itemDetail.household_max_income) : unitsValidate && Number(itemDetail.household_min_income) >= Number(itemDetail.household_max_income) ? "Invalid income range" : null}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>{!isDisabled && 
                                                        <Tooltip title="Delete" arrow>
                                                            <IconButton 
                                                                onClick={() => { 
                                                                    if (isLotteryDateUpdated()) {
                                                                        setEditAlert(true);
                                                                    } else {
                                                                        setDeleteUnitDetailPopup({ index: index, itemIndex: itemIndex, unitDetail: units[index].unitDetails }) }
                                                                    }}
                                                                aria-label="Delete"
                                                            >
                                                                <DeleteOutlineIcon sx={{ color: '#343487' }} />
                                                            </IconButton>
                                                        </Tooltip>}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {!isDisabled && 
                                    <Button 
                                        onClick={() => { 
                                            if (isLotteryDateUpdated()) {
                                                setEditAlert(true);
                                            } else {
                                            dispatch(addSubUnit(index)) 
                                            }
                                        }}
                                        type="text" size="small" 
                                        className="fs-14" 
                                        aria-label={item.unitDetails && item.unitDetails.length ? "+ Add another unit" : "+ Add unit"}
                                    >
                                        {item.unitDetails && item.unitDetails.length ? "+ Add another unit" : "+ Add unit"}
                                    </Button>}
                                {(gallery && gallery.index >= 0 && gallery.itemIndex >= 0) &&
                                    <ImageUpload
                                        open={gallery ? true : false}
                                        acceptFiles={"image"}
                                        isDisabled={isDisabled}
                                        onAdd={(file) => { dispatch(updateUnitPhotos({ file: file, index: gallery.index, itemIndex: gallery.itemIndex })) }}
                                        onDelete={(indx) => { dispatch(deleteUnitPhotos({ delIndex: indx, index: gallery.index, itemIndex: gallery.itemIndex })) }}
                                        handleClose={() => { openGallery(false) }}
                                        closeGallery={() => { openGallery(false) }}
                                        multiple={true}
                                        data={unitPhotos && unitPhotos[`${gallery.index}_${gallery.itemIndex}`] ? unitPhotos[`${gallery.index}_${gallery.itemIndex}`] : []}
                                    />
                                }
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            </Grid>
            {(deleteUnitPopup !== false) &&
                <Dialog
                    open={deleteUnitPopup !== false}
                    handleDialogClose={() => {
                        setDeleteUnitPopup(false);
                    }}
                    confirmHandle={() => {
                        dispatch(deleteUnit(deleteUnitPopup))
                        const deletedIndexVal = deleteUnitPopup.units[deleteUnitPopup.index]
                        let unit_type = unitTypesOptions.find((f) => (deletedIndexVal && deletedIndexVal.unit_type && f.id === deletedIndexVal.unit_type))
                        unit_type = unit_type?.name || ""
                        dispatch(successMessage(`You have deleted the unit type ${unit_type} from the property. To save your changes click on Update`))
                        setDeleteUnitPopup(false);
                    }}
                    successButton={"Yes"}
                    failButton={"No"}
                >
                    <Typography variant='h6'>{`Are you sure you want to delete the unit?`}</Typography>
                </Dialog>}
            {deleteUnitDetailPopup &&
                <Dialog
                    open={!!deleteUnitDetailPopup}
                    handleDialogClose={() => {
                        setDeleteUnitDetailPopup(false);
                    }}
                    confirmHandle={() => {
                        dispatch(deleteUnitDetail(deleteUnitDetailPopup))
                        const unit_no = deleteUnitDetailPopup['unitDetail'][deleteUnitDetailPopup['itemIndex']].unit_no
                        dispatch(successMessage(`You have deleted unit number ${unit_no} from the property. To save your changes click on Update`))
                        setDeleteUnitDetailPopup(false);
                    }}
                    successButton={"Yes"}
                    failButton={"No"}
                >
                    <Typography variant='h6'>{`Are you sure you want to delete the unit detail?`}</Typography>
                </Dialog>}
                { isPropertyManager && approvalStatus !== undefined &&
                <Dialog
                    open={editAlert}
                    handleDialogClose={() => { setEditAlert(false); }}
                    confirmHandle={() => { setEditAlert(false); }}
                    showCancelBtn={false}
                    successButton={"Ok"}
                >
                    <Typography variant='h6'>Please note that any updates in the Units & Rents tab won’t initiate a new lottery process. To initiate a new lottery process, please update the lottery dates</Typography>
                </Dialog>
            }
        </>
    );
}

// default props
UnitsRents.defaultProps = {
    isDisabled: false,
    unitsValidate: false
};

// prop types
UnitsRents.propTypes = {
    isDisabled: PropTypes.bool,
    unitsValidate: PropTypes.bool,
};

export default styled(UnitsRents)(Styles);
