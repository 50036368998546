import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid, styled } from "@mui/material";
import { Timeline as Core } from '@mui/lab';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import _ from "lodash";

// Imports Styles
import Styles from "./style";

// components
import { Typography } from "../typography";

// helpers
import { applicationsStatusPlOptions, globalDate, tenantsStatusPlOptions } from "../../helpers";
import { LogIcon } from "../../assets/svg";
import { useDispatch } from "react-redux";
import { downloadLotteryResultRequest } from "../../redux/reducer/runLotteryReducer";

const PropertyTimeLineComponent = (props) => {

    const dispatch = useDispatch();

    const downloadLotteryResult = (property_id, lottery_id) => {
        dispatch(downloadLotteryResultRequest({property_id, lottery_id}))
    }

    //get props
    const { className, logs, ...rest } = props;

    return (
        <Core
            sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                },
            }}
            {...rest}
            className={className}
        >
            <>
                {logs && logs.map((item, index) => {
                    let status = applicationsStatusPlOptions[item.status || "0"]
                    if (item.is_tenant) {
                        status = tenantsStatusPlOptions[item.status || "1"]
                    }

                    let user = ""
                    if ([2, 3, 4, 5, 7, 8, 9, 14].includes(item.status)) {
                        user = "Property manager"
                    }

                    return (
                        <TimelineItem key={`sd-sdf-${index}`}
                            className={status}
                        >
                            <TimelineSeparator>
                                <Grid className="logIcon">
                                    <LogIcon />
                                </Grid>
                                {/* <TimelineDot /> */}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography variant="body1"><span className="statusText" style={{ paddingRight: '8px', color: '#F3685D' }}>
                                    {item.logs}
                                </span>{`by ${item.user_group}`}</Typography>
                                <Typography variant="body1" color={"greyshades.main"}>{globalDate(item.created_at)}</Typography>
                                {item.comments && <Typography variant="body1" color={"greyshades.main"} className="link" onClick={() => downloadLotteryResult(item.property_id, item.lottery_id)}>{item.comments}</Typography>}
                            </TimelineContent>
                        </TimelineItem>
                    )
                })}
            </>
        </Core>
    );
};

// default props
PropertyTimeLineComponent.defaultProps = {
    className: "",
    logs: []
};

// prop types
PropertyTimeLineComponent.propTypes = {
    className: PropTypes.string,
    logs: PropTypes.array
};

function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps, nextProps);
}

// export component
export const PropertyTimeLine = memo(styled(PropertyTimeLineComponent)(Styles), areEqual);
