import { createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import moment from 'moment';

// helpers
import { defaultExcelType } from '../../helpers';

const initialState = {
    isLoading: false,
    listView: {
        data: [],
        pagination: {
            skip: 0,
            limit: 9,
            count: 0,
            sortField: "lottery_index",
            sortBy: 'asc'
        }
    },
    docsList: [],
    tmpfilter: {
        applicantsFilter: [],
        statusFilter: [],
        lotteryFilter: [],
        unitTypeFilter: [],
        affordabilityLevelFilter: [],
        preferenceFilter: [],
        startDate: "",
        endDate: "",
    },
    filter: {
        applicantsFilter: [],
        statusFilter: [],
        lotteryFilter: [],
        unitTypeFilter: [],
        affordabilityLevelFilter: [],
        preferenceFilter: [],
        startDate: "",
        endDate: "",
    },
    checkedValues: [],
    applicationsFileName: "",
    unitDetails: [],
    waitingListLoaders: {
        getListLoader: false,
        getCountLoader: false,
    },
    overallWaitingListLoader: false
}

const waitingListSlice = createSlice({
    name: 'waitingList',
    initialState: initialState,
    reducers: {
        // get waiting list
        getWaitingListRequest(state) {
            state.isLoading = true
            state.waitingListLoaders.getListLoader = true
        },
        getWaitingListSuccess(state, action) {
            state.listView = {
                data: action.payload.data || [],
                pagination: {
                    ...state.listView.pagination,
                    ...action.payload.pagination
                }
            };
            state.isLoading = false
            state.waitingListLoaders.getListLoader = false
        },
        getWaitingListFailure(state) {
            state.isLoading = false
            state.waitingListLoaders.getListLoader = false
        },

        // get waiting list total count
        getWaitingListCountRequest(state) {
            state.isLoading = true
            state.waitingListLoaders.getCountLoader = true
        },
        getWaitingListCountSuccess(state, action) {
            state.clearfilter = false
            state.listView.pagination = {
                ...state.listView.pagination,
                count: action.payload.data.count
            };
            state.checkedValues = []
            state.isLoading = false
            state.waitingListLoaders.getCountLoader = false
        },
        getWaitingListCountFailure(state) {
            state.isLoading = false
            state.waitingListLoaders.getCountLoader = false
        },

        updateCheckedValues(state, action) {
            let idList = [...state.checkedValues];
            const curVal = action.payload.id;
            const curValIndex = idList.findIndex(f => (f.id === curVal));
            if (curValIndex > -1) {
                idList.splice(curValIndex, 1)
            } else {
                idList.push(action.payload)
            }
            state.checkedValues = idList
        },

        // request documents from applicants
        requestDocumentRequest(state) {
            state.isLoading = true
        },
        requestDocumentSuccess(state, action) {
            const applicantIds = action.payload.applicant_ids || []
            let tmpData = [...state.listView.data];
            tmpData.map(m => {
                if (applicantIds.includes(m.id)) {
                    m.approval_status = 5
                    m.ap_status = "Document Requested"
                }
            })
            state.listView.data = tmpData
            state.isLoading = false
            state.checkedValues = []
        },
        requestDocumentFailure(state) {
            state.isLoading = false
        },

        downloadDocumentsRequest(state) {
            state.isLoading = true
            state.docsList = initialState.docsList
        },
        downloadDocumentsSuccess(state, action) {
            state.isLoading = false
            state.docsList = action.payload
        },
        downloadDocumentsFailure(state) {
            state.isLoading = false
            state.docsList = initialState.docsList
        },

        // update waiting list filter
        updateWaitingListFilter(state, action) {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value
            }
            state.clearfilter = false
        },

        // Revert filter to temporary filter values
        revertWaitingListFilter(state, action){
            state.filter = {
                ...state.tmpfilter
            }
        },

        //update temporary filter
        updateTempWaitingListFilter(state, action){
            state.tmpfilter = {
                ...state.filter
            }
        },

        //clear waiting list filter
        clearWaitingListFilter(state) {
            state.filter = {
                applicantsFilter: [],
                statusFilter: [],
                lotteryFilter: [],
                unitTypeFilter: [],
                affordabilityLevelFilter: [],
                preferenceFilter: [],
                startDate: "",
                endDate: "",
            }
            state.clearfilter = true
        },

        //download waiting list list
        downloadWaitingListRequest(state, action) {
            state.applicationsFileName = `waitinglist_${moment().format("YYYY-MM-DD_h-mm-ssA")}.xlsx`
            state.isLoading = true
        },
        downloadWaitingListSuccess(state, action) {
            state.isLoading = false
            const blob = new Blob([action.payload.data], { type: defaultExcelType });
            saveAs(blob, state.applicationsFileName || "report.xlsx");
            state.applicationsFileName = ''
        },
        downloadWaitingListFailure(state) {
            state.isLoading = false
            state.applicationsFileName = ''
        },

        approveDocumentsRequest(state) {
            state.isLoading = true
        },
        approveDocumentsSuccess(state, action) {
            state.isLoading = false

            const id = action.payload.id;
            const elementIndex = state.listView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.listView.data[elementIndex];
                indexData.approval_status = 7
                indexData.ap_status = "Document Approved"
                state.listView.data[elementIndex] = indexData
            }
        },
        approveDocumentsFailure(state) {
            state.isLoading = false
        },

        // reject application
        rejectApplicationRequest(state) {
            state.isLoading = true
        },
        rejectApplicationSuccess(state, action) {
            const id = action.payload.id;
            const approvalStatus = Number(action.payload.approval_status)
            const elementIndex = state.listView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                if(approvalStatus === 8){
                    let indexData = state.listView.data[elementIndex];
                    indexData.approval_status = approvalStatus
                    indexData.ap_status = "Document Rejected"
                    state.listView.data[elementIndex] = indexData
                }else{
                    state.listView.data.splice(elementIndex,1)
                }
            }
            state.isLoading = false
        },
        rejectApplicationFailure(state) {
            state.isLoading = false
        },

        // get property unit details
        propertyUnitDetailsRequest(state) {
            state.isLoading = true
            state.unitDetails = initialState.unitDetails
        },
        propertyUnitDetailsSuccess(state, action) {
            state.isLoading = false
            state.unitDetails = action.payload
        },
        propertyUnitDetailsFailure(state) {
            state.isLoading = false
            state.unitDetails = initialState.unitDetails
        },

        offerUnitRequest(state) {
            state.isLoading = true
        },
        offerUnitSuccess(state, action) {
            const payload  = action.payload.payload || {};
            const applicationId = payload.applicationId
            const elementIndex = state.listView.data.findIndex(f => (f.id === applicationId))
            if (elementIndex >= 0) {
                let indexData = state.listView.data[elementIndex];
                indexData.approval_status = 9
                indexData.ap_status = "Offered"
                state.listView.data[elementIndex] = indexData
            }
            state.isLoading = false
        },
        offerUnitFailure(state) {
            state.isLoading = false
        },

        moveApplicantToWlRequest(state) {
            state.isLoading = true
        },
        moveApplicantToWlSuccess(state, action) {
            const id = action.payload.applicationId;
            const elementIndex = state.listView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.listView.data[elementIndex];
                indexData.approval_status = 4
                indexData.ap_status = "Waiting List"
                state.listView.data[elementIndex] = indexData
            }
            state.isLoading = false
        },
        moveApplicantToWlFailure(state) {
            state.isLoading = false
        },
        updateOverallWaitingListLoaders(state){
            state.overallWaitingListLoader = Object.values(state.waitingListLoaders).some(loaderValue => loaderValue)
        }

    }
})

export const {
    getWaitingListRequest, getWaitingListSuccess, getWaitingListFailure,
    getWaitingListCountRequest, getWaitingListCountSuccess, getWaitingListCountFailure,
    updateCheckedValues,
    requestDocumentRequest, requestDocumentSuccess, requestDocumentFailure,
    downloadDocumentsRequest, downloadDocumentsSuccess, downloadDocumentsFailure,
    updateWaitingListFilter, clearWaitingListFilter, updateTempWaitingListFilter, revertWaitingListFilter,
    downloadWaitingListRequest, downloadWaitingListSuccess, downloadWaitingListFailure,
    approveDocumentsRequest, approveDocumentsSuccess, approveDocumentsFailure,
    moveApplicantToWlRequest,moveApplicantToWlSuccess,moveApplicantToWlFailure,
    rejectApplicationRequest, rejectApplicationSuccess, rejectApplicationFailure,
    propertyUnitDetailsRequest, propertyUnitDetailsSuccess, propertyUnitDetailsFailure,
    offerUnitRequest,offerUnitSuccess,offerUnitFailure,
    updateOverallWaitingListLoaders
} = waitingListSlice.actions;
export default waitingListSlice.reducer;