import React, { memo } from "react";
import PropTypes from "prop-types";
import { styled, Dialog as Core, Button, Grid } from "@mui/material";
import _ from "lodash";

// Imports Styles
import Styles from "./style";

const DialogComponent = (props) => {
    //get props
    const {
        className,
        size,
        children,
        handleDialogClose,
        open,
        fullWidth,
        maxWidth,
        confirmHandle,
        successButton,
        failButton,
        showSuccessBtn,
        showCancelBtn,
        ...rest
    } = props;

    return (
        <Core
            open={open}
            className={className}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            onClose={handleDialogClose}
            aria-label="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            {...rest}
        >
            {children}
            <Grid className="actions" align="right">
                {showCancelBtn && <Button variant="text" size="medium" className="mr-1" onClick={handleDialogClose} aria-label={failButton}>{failButton}</Button>}
                {showSuccessBtn && <Button variant="contained" size="medium" color="primary" disableElevation onClick={confirmHandle} aria-label={successButton}>{successButton}</Button>}
            </Grid>
        </Core>
    );
};

// default props
DialogComponent.defaultProps = {
    fullWidth: true,
    maxWidth: "xs",
    className: "",
    size: "small",
    successButton:"OK",
    failButton:"Cancel",
    handleDialogClose:()=>{},
    open:false,
    confirmHandle:()=>{},
    showSuccessBtn: true,
    showCancelBtn: true,
};

// prop types
DialogComponent.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    confirmHandle: PropTypes.func,
    open: PropTypes.bool,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    handleDialogClose:PropTypes.func,
    successButton:PropTypes.string,
    failButton:PropTypes.string,
    showSuccessBtn: PropTypes.bool,
    showCancelBtn: PropTypes.bool,
};

function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps, nextProps);
}

// export component
export const Dialog = memo(styled(DialogComponent)(Styles), areEqual);
