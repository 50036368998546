import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Styles from '../style'

// helpers
import { isRequired, statusOptions, preferenceOptions, isNumberValid, offerValidityOptions, getImage, adaOptions, reportPeriodOptions, amiRmiOptions, isDateValid, isNjZipCodeValid, gets3URL } from "../../../helpers";

// reducer
import { updateGeneral, updatePhotos, deletePhotos, updatePropertyStatusRequest,  } from "../../../redux/reducer/propertiesReducer";

// service
import { locationService } from "../../../redux/service";

// components
import { AutoComplete, DatePicker, TextField, Maps, ImageUpload, AsyncAutoComplete, Dialog } from "../../../components";
import Amentites from "./amentites";
import { GalleryIcon, WarningIcon } from "../../../assets/svg";

// error message
import { errorMessage } from "../../../redux/reducer/uiReducer";

const Property = (props) => {
    const inputRef = useRef(null);

    //define dispatch
    const dispatch = useDispatch();

    // state
    const [openGallery, setOpenGallery] = useState(false);
    const [inactivePopup, setInactivePopup] = useState(false);
    const [editAlert, setEditAlert] = useState(false);

    // props
    const { validate, isDisabled } = props;

    //reducer
    const { currentProperty: { general, general: propertiesDetail, photos }, isUnitsEdited, hasUnitPhotosChanges, unitChangedFields
} = useSelector(s => (s.properties))
    const { loggedUser } = useSelector(s => (s.profile));
    const approvalStatus = useSelector((state) => state.properties.currentProperty.general.approval_status);
    console.log('Approval Status:', approvalStatus, 'Type:', typeof approvalStatus);

    // variables
    const isPropertyManager = loggedUser.user_group_id === 2
    const isCityStaff = loggedUser.user_group_id === 3

    useEffect(() => {
        if(inputRef && inputRef.current){
            inputRef.current.focus();
        }
    }, [dispatch]);

    const updateGeneralData = (data) => {
        dispatch(updateGeneral({ ...general, ...data }))
    }

    // get city options
    async function getCityOptions(key) {
        const regionId = general.state && general.state.id ? general.state.id : ""
        const cities = await locationService.getCities(key, regionId);
        return cities;
    }

    // get state options
    async function getStateOptions(key) {
        const cityId = general.city && general.city.id ? general.city.id : ""
        const states = await locationService.getStates(key, cityId);
        return states;
    }

    const isFieldDisabled = 
    approvalStatus === 1 || 
    isCityStaff || 
    !(approvalStatus === 0 || approvalStatus === 2 || approvalStatus === 3) || 
    !isPropertyManager ||
	propertiesDetail.lottery_count > 0;

    return (
        <Grid container spacing={4} className="createContainer pb-3">
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">PROPERTY NAME (OPTIONAL)</Typography>
                <TextField
                    id={"name"}
                    name={"name"}
                    inputRef={inputRef}
                    value={general.name}
                    aria-label="Enter Property name (optional)"
                    className={`inlineEdit sm ${general.name ? '' : 'nodata'}`}
                    type={"text"}
                    disabled={isDisabled || propertiesDetail.lottery_count > 0}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value }) }}
                />
            </Grid>
            <Tooltip title={general.desc} arrow>
                <Grid item xs={8}>
                    <Typography variant="caption" color="textSecondary">DESCRIPTION</Typography>
                        <TextField
                            id={"desc"}
                            name={"desc"}
                            aria-label="Enter Description"
                            value={general.desc}
                            className={`inlineEdit sm ${general.desc ? '' : 'nodata'}`}
                            type={"text"}
                            disabled={isDisabled}
                            handleChange={(e) => { updateGeneralData({ [e.name]: e.value }) }}
                            validation={isRequired(validate, general.desc)}
                        />
                </Grid>
            </Tooltip>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">STATUS</Typography>
                <AutoComplete
                    id={"status"}
                    name={"status"}
                    aria-label="Select status"
                    value={{ id: general.status }}
                    options={statusOptions}
                    disabled={isPropertyManager ? (isDisabled || propertiesDetail.lottery_count > 0) : false}
                    className={`inlineEdit sm ${general.status ? '' : 'nodata'}`}
                    handleChange={(e) => {
                        if (!isPropertyManager) {
                            setInactivePopup({ id: general.id, status: e.value && e.value.id ? e.value.id : "" })
                        } else {
                            updateGeneralData({ [e.name]: e.value && e.value.id ? e.value.id : "" })
                        }
                    }}
                />
            </Grid>
            <Grid item xs={8}>
                <Typography variant="caption" color="textSecondary">STREET ADDRESS</Typography>
                <TextField
                    id={"address"}
                    name={"address"}
                    aria-label="Enter Street address"
                    value={general.address}
                    className={`inlineEdit sm  ${general.address ? '' : 'nodata'}`}
                    type={"text"}
                    disabled={isDisabled || propertiesDetail.lottery_count > 0}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value }) }}
                    validation={isRequired(validate, general.address)}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">CITY</Typography>
                <AsyncAutoComplete
                    id={"city"}
                    name={"city"}
                    aria-label="Enter city"
                    value={general.city}
                    className={`inlineEdit sm  ${general.city ? '' : 'nodata'}`}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value }) }}
                    initialValue={general.city}
                    apiCallback={getCityOptions}
                    matchBy="id"
                    disabled={isDisabled || propertiesDetail.lottery_count > 0}
                    display="name"
                    validation={isRequired(validate, general.city && general.city.id ? general.city.id : '')}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">STATE</Typography>
                <AsyncAutoComplete
                    id={"state"}
                    name={"state"}
                    aria-label="Select State"
                    className={`inlineEdit sm  ${general.state ? '' : 'nodata'}`}
                    initialValue={general.state}
                    value={general.state}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value }) }}
                    apiCallback={getStateOptions}
                    matchBy="id"
                    disabled={isDisabled || propertiesDetail.lottery_count > 0}
                    display="name"
                    validation={isRequired(validate, general.state && general.state.id ? general.state.id : '')}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">ZIP CODE</Typography>
                <TextField
                    id={"zipcode"}
                    name={"zipcode"}
                    aria-label="Enter zip code"
                    value={general.zipcode}
                    className={`inlineEdit sm  ${general.zipcode ? '' : 'nodata'}`}
                    type={"text"}
                    disabled={isDisabled || propertiesDetail.lottery_count > 0}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value }) }}
                    validation={isNjZipCodeValid(validate, general.zipcode)}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">LATITUDE</Typography>
                <TextField
                    id={"lat"}
                    name={"lat_long"}
                    aria-label="Enter Latitude"
                    value={general.lat_long[0]}
                    className={`inlineEdit sm  ${general.lat_long[0] ? '' : 'nodata'}`}
                    type={"number"}
                    disabled={isDisabled || propertiesDetail.lottery_count > 0}
                    handleChange={(e) => {
                        if(Math.abs(e.value) <= 90){
                            updateGeneralData({ [e.name]: [e.value, general.lat_long[1]] })
                        }else{
                            dispatch(errorMessage("Latitude should be between -90 to 90"))
                        }
                    }}
                    validation={isNumberValid(validate, general.lat_long && general.lat_long[0])}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">LONGITUDE</Typography>
                <TextField
                    id={"long"}
                    name={"lat_long"}
                    aria-label="Enter Longitude"
                    value={general.lat_long[1]}
                    className={`inlineEdit sm  ${general.lat_long[1] ? '' : 'nodata'}`}
                    type={"number"}
                    disabled={isDisabled || propertiesDetail.lottery_count > 0}
                    handleChange={(e) => {
                        if(Math.abs(e.value) <= 180){
                            updateGeneralData({ [e.name]: [general.lat_long[0], e.value] })
                        }else{
                            dispatch(errorMessage("Longitude should be between -180 to 180"))
                        }
                    }}
                    validation={isNumberValid(validate, general.lat_long && general.lat_long[1])}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">YEAR BUILT</Typography>
                <DatePicker
                    className={`inlineEdit sm  ${general.built_year ? '' : 'nodata'} datePicker`}
                    name="built_year"
                    aria-label="Enter year built"
                    value={general.built_year}
                    disabled={isDisabled || propertiesDetail.lottery_count > 0}
                    views={['year']}
                    disableFuture={true}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value }) }}
                    validation={isDateValid(validate, general.built_year,null,true,false)}
                    placeholder="YYYY"
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">APPLICATION START DATE</Typography>
                <DatePicker
                    className={`inlineEdit sm  ${general.application_date ? '' : 'nodata'} datePicker`}
                    name="application_date"
                    aria-label="Enter Application start date"
                    value={general.application_date}
                    disabled={isDisabled}
                    disablePast={true}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value }) }}
                    validation={isDateValid(
                        validate, 
                        general.application_date,
                        null,
                        false,
                        general?.id ? false : true
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary" className="relative">DOCUMENT SUBMISSION PERIOD</Typography>
                <AutoComplete
                    className={`inlineEdit sm  ${general.offer_valid_at ? '' : 'nodata'}`}
                    name="offer_valid_at"
                    aria-label="Enter document submission period"
                    options={offerValidityOptions}
                    value={{ id: general.offer_valid_at }}
                    matchBy="id"
                    display="value"
                    disabled={isDisabled}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value && e.value.id ? e.value.id : "" }) }}
                    validation={isRequired(validate, general.offer_valid_at)}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">APPLICATION DEADLINE</Typography>
                <DatePicker
                    className={`inlineEdit sm  ${general.application_deadline ? '' : 'nodata'} datePicker`}
                    name="application_deadline"
                    aria-label="Enter Application deadline"
                    value={general.application_deadline}
                    disabled={isDisabled}
                    disablePast={true}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value }) }}
                    validation={isDateValid(
                        validate, 
                        general.application_deadline,
                        null,
                        false,
                        general?.id ? false : true
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">EARLIEST MOVE-IN DATE</Typography>
                <DatePicker
                    className={`inlineEdit sm  ${general.available_at ? '' : 'nodata'} datePicker`}
                    name="available_at"
                    aria-label="Enter earliest move-in date"
                    value={general.available_at}
                    disabled={isDisabled}
                    disablePast={true}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value }) }}
                    validation={isDateValid(
                        validate, 
                        general.available_at,
                        null,
                        false,
                        general?.id ? false : true
                    )}
                />
            </Grid>

            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">LOTTERY DATE</Typography>
                <DatePicker
                    className={`inlineEdit sm  ${general.lottery_date ? '' : 'nodata'} datePicker`}
                    name="lottery_date"
                    aria-label="Enter lottery date"
                    value={general.lottery_date}
                    disabled={isDisabled}
                    disablePast={true}
                    handleChange={(e) => {
                        if (approvalStatus !== undefined && 
                                ( isUnitsEdited || 
                                  hasUnitPhotosChanges || 
                                  unitChangedFields.length > 0)
                                ) {
                            setEditAlert(true);
                        } else {
                            updateGeneralData({ [e.name]: e.value }) 
                        }
                    }}
                    validation={isDateValid(
                        validate, 
                        general.lottery_date,
                        null,
                        false,
                        general?.id ? false : true
                    )}
                />
            </Grid>

            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">PREFERENCE</Typography>
                <AutoComplete
                    id={"preference"}
                    name={"preference"}
                    aria-label="Select preference"
                    value={{ id: general.preference }}
                    className={`inlineEdit sm  ${general.preference ? '' : 'nodata'}`}
                    type={"text"}
                    disabled={isDisabled}
                    options={preferenceOptions}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value && e.value.id ? e.value.id : "" }) }}
                    validation={isRequired(validate, general.preference)}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">ADA ACCESSIBILITY REQUIREMENT</Typography>
                <AutoComplete
                    id={"is_ada"}
                    name={"is_ada"}
                    aria-label="Select ada accessibility requirement"
                    value={{ id: general.is_ada }}
                    className={`inlineEdit sm ${general.is_ada===null && 'nodata'}`}
                    disabled={isDisabled}
                    options={adaOptions}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value && e.value.id ? e.value.id : "" }) }}
                    validation={isRequired(validate, general.is_ada)}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">REPORT PERIOD</Typography>
                <AutoComplete
                    id={"report_period"}
                    name={"report_period"}
                    aria-label="Select report period"
                    value={{ id: general.report_period }}
                    className={`inlineEdit sm  ${general.report_period == 0 && 'nodata'}`}
                    type={"text"}
                    disabled={isDisabled || propertiesDetail.lottery_count > 0}
                    options={reportPeriodOptions}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value && e.value.id ? e.value.id : "" }) }}
                    validation={isRequired(validate, general.report_period === "0" ? '' : general.report_period )}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">Standard</Typography>
                <AutoComplete
                    id={"ami_rmi"}
                    name={"ami_rmi"}
                    aria-label="Select standard"
                    value={{ id: general.ami_rmi }}
                    className={`inlineEdit sm ${general.ami_rmi == 0 && 'nodata'}`}
                    type={"text"}
                    disabled={isDisabled || propertiesDetail.lottery_count > 0}
                    options={amiRmiOptions}
                    handleChange={(e) => { updateGeneralData({ [e.name]: e.value && e.value.id ? e.value.id : "" }) }}
                    validation={isRequired(validate, general.ami_rmi === "0" ? '' : general.ami_rmi)}
                />
            </Grid>
            <Grid item xs={12} sx={{ zIndex: 0 }}>
                <Grid container spacing={4}>
                    <Grid item xs={7}>
                        <Typography variant="caption" color="textSecondary" className="pb5">LOCATION</Typography>
                        <Grid className="map mt8">
                            <Maps
                                position={general.lat_long && general.lat_long[0] && general.lat_long[0] < 200 && general.lat_long[1] && general.lat_long[1] < 200 ? general.lat_long: [] }
                                isDisabled={isDisabled}
                                setPosition={(value) => { updateGeneralData({ lat_long: value }) }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="caption" color="textSecondary" className="pb5">PHOTOS</Typography>
                        <Grid className={`${(photos && photos.length > 0) && 'imagesSelected'} photos mt8`} onClick={(event) => { setOpenGallery(true) }}>
                            {(photos && photos.length > 0) ?
                                <Grid className={`photosList ${(photos && photos.length === 1) && 'singleimgList'}`}>
                                    {
                                        [...(photos || [])].slice(0, 2).map((item, index) => (
                                            <Grid className="listImg" key={`dfk-${index}`}>
                                                <img src={typeof item === "object" && item.photo ? gets3URL(item.photo) : getImage(item)} alt="Property Image" />
                                                <Grid className="moreimgCount" tabIndex="0" aria-label="Property Photos"><Tooltip arrow title="View Photos"><span className="photoview"><GalleryIcon />{photos.length} Photos</span></Tooltip> 
                                                {/* {isDisabled ? "" : <span className="insert">+ Insert Photos</span>} */}
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                                :
                                <Button
                                    variant="text"
                                    className={`insertPhotos`}
                                    onClick={() => { setOpenGallery(true) }}
                                    aria-label={isDisabled ? "View Photos" : "+ Insert Photos"}
                                >
                                    {isDisabled ? "View Photos" : "+ Insert Photos"}
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Amentites isDisabled={isDisabled} />
            <ImageUpload
                open={openGallery}
                isDisabled={isDisabled}
                acceptFiles={"image"}
                onAdd={(file) => { dispatch(updatePhotos(file)) }}
                onDelete={(index) => { dispatch(deletePhotos(index)) }}
                handleClose={() => { setOpenGallery(false) }}
                closeGallery={() => { setOpenGallery(false) }}
                data={photos}
                multiple={true}
            />
            {inactivePopup &&
                <Dialog
                    open={!!inactivePopup}
                    handleDialogClose={() => {
                        setInactivePopup(false);
                    }}
                    confirmHandle={() => {
                        dispatch(updatePropertyStatusRequest(inactivePopup));
                        updateGeneralData({ status: inactivePopup.status })
                        setInactivePopup(false);
                    }}
                    successButton={"Yes"}
                    failButton={"No"}
                >
                    <Typography variant='h6'>{`Are you sure you want to make the property ${inactivePopup && inactivePopup.status === "1" ? "Active" : "Inactive"}?`}</Typography>
                </Dialog>}
                { isPropertyManager && approvalStatus  !== undefined &&
                <Dialog
                    open={editAlert}
                    handleDialogClose={() => { setEditAlert(false); }}
                    confirmHandle={() => { setEditAlert(false); }}
                    showCancelBtn={false}
                    successButton={"Ok"}
                >
                    <Typography variant='h6'>Changing the lottery date will initiate a new lottery process. Please note that any updates in the Units & Rents tab won’t be submitted for approval. To modify 'Units and Rents,' please handle them in as separate request</Typography>
                </Dialog>
            }
        </Grid>
    );
}

// default props
Property.defaultProps = {
    isDisabled: false
};

// prop types
Property.propTypes = {
    isDisabled: PropTypes.bool,
};

export default styled(Property)(Styles);
