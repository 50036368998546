import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { Dialog, Grid, Typography, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from "@mui/material";
import Lottie from 'react-lottie-player'

//style
import Style from './style'
import { LotteryICon, LotterySuccessIcon } from "../../assets/svg";
import AnimationImage from '../../assets/animation/NJ_Spinner.json'

//Components
import { Button, Avatar } from "../../components";

// reducer
import { runLotteryRequest, downloadLotteryResultRequest, resetLotteryResult } from "../../redux/reducer/runLotteryReducer";

// helpers
import { gets3URL } from "../../helpers";

function Lottery(props) {
    //define dispatch
    const dispatch = useDispatch();

    const { className, propertiesDetail, lotteryCount } = props;

    const { lotteryResult } = useSelector(s => (s.runLottery))

    //pagination
    const [open, setOpen] = useState(false);
    const [showLotteryRun, setShowLotteryRun] = useState(false);

    useEffect(() => {
        if (lotteryResult && lotteryResult.length) {
            setShowLotteryRun(true)
            setOpen(true)
            setTimeout(() => {
                setShowLotteryRun(false)
            }, 2000);
        }
    }, [lotteryResult])

    const handleClose = () => {
        dispatch(resetLotteryResult())
        setOpen(false);
    };

    const handleRunLottery = () => {
        dispatch(runLotteryRequest({ property_id: propertiesDetail.id }))
    }

    const downloadLotteryResult = () => {
        dispatch(downloadLotteryResultRequest({ property_id: propertiesDetail.id }))
    }

    return (
        <>
            {propertiesDetail.lottery_active && 
                <Button 
                    onClick={() => { handleRunLottery() }} 
                    variant={"contained"} 
                    size="medium" 
                    color="secondary" 
                    className={"mr-1"} 
                    sx={{ color: '#fff' }} 
                    startIcon={<LotteryICon />} 
                    aria-label={lotteryCount > 0 ? "Re-Run Lottery" : "Run Lottery"}
                >
                    {lotteryCount > 0 ? "Re-Run Lottery" : "Run Lottery"}
                </Button>}
            <Dialog
                fullWidth
                maxWidth={"md"}
                open={open}
                onClose={() => { handleClose() }}
                aria-label="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={`${className} dialogLottery`}
            >
                <Grid className="lotteryContainer">
                    {showLotteryRun ?
                        <Grid item xs={12} align="center" className="dflex alignCenter justifyCenter" sx={{ minHeight: '496px' }}>
                            <Lottie
                                loop
                                animationData={AnimationImage}
                                play
                                style={{ width: 470, height: 470 }}
                            />
                        </Grid>
                        :
                        <>
                            <Grid align="center">
                                <LotterySuccessIcon />
                            </Grid>
                            <Typography variant="h2" className=" pb-3 fw-500" align="center">Lottery has been run successfully!!!</Typography>
                            <TableContainer className="tableContainer" sx={{maxHeight: 'calc(100vh/4)'}} component={Paper}>
                                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><Typography variant="body1" className="fw-500" align="center">Applicant Number</Typography></TableCell>
                                            <TableCell><Typography variant="body1" className="fw-500">Unit Type</Typography></TableCell>
                                            <TableCell><Typography variant="body1" className="fw-500">Affordability Level</Typography></TableCell>
                                            <TableCell><Typography variant="body1" className="fw-500" align="center">Lottery Number</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {lotteryResult && lotteryResult.map((item, index) => (
                                            <TableRow key={`hek-ssd-${index}`}>
                                                <TableCell><Typography variant="body1" align="center">{item.application_number || ""}</Typography></TableCell>
                                                <TableCell><Typography variant="body1">{item.unit_type || ""}</Typography></TableCell>
                                                <TableCell><Typography variant="body1">{item.affordability_level || ""}</Typography></TableCell>
                                                <TableCell><Typography variant="body1" align="center">{item.lottery_number || ""}</Typography></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid className="dflex alignCenter justifyCenter pt-4">
                                <Button variant={"contained"} color="primary" size={"large"} onClick={() => { downloadLotteryResult() }} aria-label="Download lottery report">Download Lottery Report</Button>
                                <Button variant={"text"} color="primary" size={"large"} className="ml-3" onClick={() => { handleClose() }} aria-label="Close">Close</Button>
                            </Grid>
                        </>
                    }
                </Grid>
            </Dialog>
        </>
    );
}

// default props
Lottery.defaultProps = {
    classes: {},
    propertiesDetail: {},
    lotteryCount: 0
};

// prop types
Lottery.propTypes = {
    classes: PropTypes.object,
    propertiesDetail: PropTypes.object,
    lotteryCount: PropTypes.number,
};

export default styled(Lottery)(Style);