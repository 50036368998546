import { combineEpics } from 'redux-observable';

// import all epics
import { authEpic } from "./authEpic";
import { globalEpic } from './globalEpic';
import { profileEpic } from './profileEpic';
import { propertiesEpic } from './propertiesEpic';
import { propertyManagerEpic } from './propertyManagerEpic';
import { applicationsEpic } from './applicationsEpic';
import { runLotteryEpic } from './runLotteryEpic';
import { waitingListEpic } from './waitingListEpic';
import { tenantsEpic } from './tenantsEpic';
import { reportsEpic } from './reportsEpic';
import { webNotificationEpic } from './webNotificationEpic';

const rootEpic = combineEpics(
    ...authEpic,
    ...profileEpic,
    ...propertyManagerEpic,
    ...propertiesEpic,
    ...globalEpic,
    ...applicationsEpic,
    ...runLotteryEpic,
    ...waitingListEpic,
    ...tenantsEpic,
    ...reportsEpic,
    ...webNotificationEpic,
);

export default rootEpic;