import React, { useState } from "react";
import PropTypes from "prop-types";
import { Autocomplete, Checkbox, Chip, Popper, Tooltip } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { styled } from "@mui/material";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import { TextField } from "../textField";
import style from "./style";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiSelectStatic = (props) => {
  // props
  const {
    className,
    customWidth,
    value,
    display,
    matchBy,
    placeholder,
    name,
    id,
    handleChange,
    options,
    handleClose,
    limitTags,
  } = props;

  // sate
  const [open, setOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const matches = useMediaQuery("(max-width:500px)");
  const [updateKey, setUpdateKey] = useState(0);

  const customPopper = function (props) {
    return (
    <Popper
      {...props}
      className={"poperComp"}
      sx={{
        width: "100% !important",
        maxWidth: "600px",
        paddingRight: `${matches && "20px"}`,
        paddingLeft: `${matches && "20px"}`,
      }}
      placement="bottom-start"
    />
  );
  };

  const optionsOrder = () => {
    const optionsOrder = [
      ...(value || []),
      ...(options || []).filter(f => {
        const isFound = value.find(u => (u.id === f.id))
        return (isFound ? false : true)
      })
    ]
    return optionsOrder
  }

  return (
    <Autocomplete
      PopperComponent={customWidth && customPopper}
      className={className}
      multiple
      id={id}
      open={open}
      onOpen={() => {
        setOpen(true);
        setHasChanges(false)
      }}
      onClose={() => {
        setOpen(false);
        if(hasChanges){
          handleClose()
        }
      }}
      options={optionsOrder()}
      disableCloseOnSelect
      loading={false}
      value={value}
      getOptionLabel={(option) => {
        if (value && (value[matchBy] === 0 || value[matchBy])) {
          return (
            option?.[display] ||
            options.find(
              (f) => f[matchBy].toString() === value[matchBy].toString()
            )?.[display] ||
            ""
          );
        } else {
          return option?.[display] || "";
        }
      }}
      limitTags={limitTags}
      disableClearable={true}
      ChipProps={{ onDelete: null }}
      ListboxProps={{
        sx: {
          "& .MuiCheckbox-root": { padding: "4px", marginRight: "5px" },
        },
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip title={option[display] || ''} arrow>
            <Chip
              label={option[display]}
              {...getTagProps({ index })}
              onDelete={() => {
                const newValue = value.filter((f) => f[matchBy] !== option[matchBy]);
                handleChange({
                  name,
                  value: newValue,
                });
                setUpdateKey((prev) => prev + 1);
              }}
            />
          </Tooltip>
        ))
      }
      renderOption={(props, option, { selected }) => {
        return (
        <li
          className="listCheckBox"
          {...props}
          key={`key-${option[matchBy]}`}
            onClick={(e) => {
            setHasChanges(true)
              const isFound = value.find((f) => f[matchBy] === option[matchBy])
              if (isFound) {
                handleChange({ name, value: value.filter((f) => f[matchBy] !== option[matchBy]) });
              } else {
                handleChange({ name, value: [...value, option] });
              }
          }}
        >
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
              checked={value.findIndex((f) => f[matchBy] === option[matchBy]) > -1}
            // onClick={(e) => {
            //   if (e.target.checked) {
            //     handleChange({ name, value: [...value, option] });
            //   } else {
            //     handleChange({name,value: value.filter((f) => f.id !== option.id)});
            //   }
            // }}
          />
          {option[display]}
        </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          aria-label={placeholder}
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
          }}
          key={id}
        />
      )}
    />
  );
};

MultiSelectStatic.defaultProps = {
  display: "value",
  matchBy: "id",
  required: false,
  label: "",
  handleChange: () => { },
  name: "async",
  options: [],
  validation: null,
  placeholder: "",
  value: [],
  initialValue: [],
  id: "",
  handleClose: () => { },
  limitTags: 2,
  customWidth: false,
};

MultiSelectStatic.propTypes = {
  display: PropTypes.string,
  matchBy: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array,
  validation: PropTypes.any,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  initialValue: PropTypes.array,
  id: PropTypes.string,
  handleClose: PropTypes.func,
  limitTags: PropTypes.number,
  customWidth: PropTypes.bool,
};

export const MultiSelect = styled(MultiSelectStatic)(style);
 