import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";

//style
import Style from './style'

// components
import { PropertyTimeLine } from "../../components";

// reducer
import { getPropertiesLogsRequest } from "../../redux/reducer/propertiesReducer";

const Logs = () => {

const dispatch = useDispatch();
const {currentProperty: { general: propertiesDetail }, logs} = useSelector(s => (s.properties))

    // get selected manager comments
    useEffect(() => {
        dispatch(getPropertiesLogsRequest({ property_id: propertiesDetail.id }))
    }, [dispatch, propertiesDetail.id])

    return (
        <Grid className="commentsContainer">
            <PropertyTimeLine
                logs={logs}
            />
        </Grid>
    );
}

// default props
Logs.defaultProps = {
    classes: {},
};

// prop types
Logs.propTypes = {
    classes: PropTypes.object,
};

export default styled(Logs)(Style);