import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Style from './style'

// components
import { Table } from "../../components";

//utils
import { convertPhoneformat } from "../../helpers";

const Detail = () => {

    //redux
    const { currentApplication: { general: applicationsDetail, family_details: familyDetail } } = useSelector(s => (s.applications))
    const { loggedUser: { user_group_id } } = useSelector(s => (s.profile))

    //variables
    const isCityStaff = user_group_id === 3
    const headers = ["Family Member", "Gender", "Relationship", "Date of Birth", "Age", "Occupation", "SSN", "Veteran", "Disability", "Senior-Citizen", "Artist", "Yearly Income"]
    const headerFields = ["family_person", "gender", "relationship", "dob", "age", "occupation", "ssn", "is_veteran", "is_disabled", "is_seniorcitizen", "is_artist", "yearly_income"]

    return (
        <Grid container className="detailContainer">
            <Grid item xs={12}>
                <Grid container spacing={5}>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">UNIT TYPE</Typography>
                        <Typography variant="h5" className="pt-1">{applicationsDetail.unit_type_name || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">AFFORDABILITY LEVEL</Typography>
                        <Typography variant="h5" className="pt-1">{applicationsDetail.affordability_level || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">HOUSEHOLD SIZE</Typography>
                        <Typography variant="h5" className="pt-1">{applicationsDetail.household_size || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">TOTAL ANNUAL HOUSEHOLD INCOME</Typography>
                        <Typography variant="h5" className="pt-1">$ {applicationsDetail.total_household_income || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">EMAIL</Typography>
                        <Typography variant="h5" className="pt-1 oneLineEllip">{applicationsDetail.email || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">PHONE NUMBER</Typography>
                        <Typography variant="h5" className="pt-1">{convertPhoneformat(applicationsDetail.phone) || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">ALTERNATE PHONE</Typography>
                        <Typography variant="h5" className="pt-1">{convertPhoneformat(applicationsDetail.alt_phone) || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">ADDRESS</Typography>
                        <Typography variant="h5" className="pt-1">{applicationsDetail.address || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">CITY</Typography>
                        <Typography variant="h5" className="pt-1">{applicationsDetail.c_disp || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">STATE</Typography>
                        <Typography variant="h5" className="pt-1">{applicationsDetail.s_disp || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">ZIP CODE</Typography>
                        <Typography variant="h5" className="pt-1">{applicationsDetail.zipcode || ""}</Typography>
                    </Grid>
                    {isCityStaff && <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">RACE</Typography>
                        <Typography variant="h5" className="pt-1">{applicationsDetail.race || ""}</Typography>
                    </Grid>}
                    {isCityStaff && <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">ETHNICITY</Typography>
                        <Typography variant="h5" className="pt-1">{applicationsDetail.ethnicity || ""}</Typography>
                    </Grid>}
                    {(applicationsDetail && (Number(applicationsDetail.ap_ada) === 1)) && 
                        <Grid item xs={4}>
                            <Typography variant="caption" color="textSecondary">ADA ACCESSIBILITY REQUIREMENT</Typography>
                            <Typography variant="h5" className="pt-1">{applicationsDetail.ada || ""}</Typography>
                        </Grid>
                    }
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">LOTTERY NUMBER</Typography>
                        <Typography variant="h5" className="pt-1">{applicationsDetail.lottery_number || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">APPLICATION NUMBER</Typography>
                        <Typography variant="h5" className="pt-1">{applicationsDetail.application_number || ""}</Typography>
                    </Grid>
                    {isCityStaff && <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">SEXUAL ORIENTATION</Typography>
                        <Typography variant="h5" className="pt-1">{applicationsDetail.sexual_orient || ""}</Typography>
                    </Grid>}
                </Grid>
            </Grid>
            <Grid item xs={12} className="pt-3 applicationTable">
                <Table
                    header={isCityStaff ? headers : headers.filter(f=>(f !== "Gender"))}
                    fields={isCityStaff ? headerFields : headerFields.filter(f=>(f !== "gender"))}
                    data={familyDetail}
                    linkFields={[]}
                    showpagination={false}
                />
            </Grid>
        </Grid>
    );
}

// default props
Detail.defaultProps = {
    classes: {},
};

// prop types
Detail.propTypes = {
    classes: PropTypes.object,
};

export default styled(Detail)(Style);