import { createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import moment from 'moment';

// helpers
import { defaultExcelType } from '../../helpers';

const initialState = {
    isLoading: false,
    isLazyLoading: false,
    viewType: "panel",
    panelView: {
        data: [],
        pagination: {
            skip: 0,
            limit: 30,
            count: 0,
            sortField: "viewed",
            sortBy: 'asc'
        }
    },
    listView: {
        data: [],
        pagination: {
            skip: 0,
            limit: 9,
            count: 0,
            sortField: "name",
            sortBy: 'asc'
        }
    },
    tenantDetail: {
        details: {},
        familyDetails: []
    },
    filter: {
        userFilter: [],
        addressFilter: [],
        statusFilter: [],
        tenantsFilter: [],
        flagFilter: [],
        preferenceFilter: [],
        unitTypeFilter: [],
        affordabilityLevelFilter: []
    },
    clearfilter : false,
    initiateSearch:false,
    tenantsLoaders: {
        getLeftPanelListLoader: false,
        getListLoader: false,
        getCountLoader: false,
        getDetailLoader: false,
        updateStatusLoader: false,
    },
    overallTenantsLoader: false
}

const tenantsSlice = createSlice({
    name: 'tenants',
    initialState: initialState,
    reducers: {
        // get list of tenants for left panel
        getTenantsLeftPanelListRequest(state) {
            state.isLazyLoading = true;
            state.tenantsLoaders.getLeftPanelListLoader = true
        },
        getTenantsLeftPanelListSuccess(state, action) {
            const isFirst = action.payload.pagination && action.payload.pagination.skip === 1 ? true : false
            state.panelView = {
                data: isFirst ? [...(action.payload.data || [])] : [...(state.panelView.data || []), ...(action.payload.data || [])],
                pagination: {
                    ...state.panelView.pagination,
                    ...action.payload.pagination
                }
            };
            state.isLazyLoading = false;
            state.tenantsLoaders.getLeftPanelListLoader = false
        },
        getTenantsLeftPanelListFailure(state) {
            state.isLazyLoading = false;
            state.tenantsLoaders.getLeftPanelListLoader = false
            state.leftPanelList = initialState.leftPanelList;
        },
        //get the total count of tenants for pagination
        getTenantsCountRequest(state) {
            state.tenantsLoaders.getCountLoader = true
        },
        getTenantsCountSuccess(state, action) {
            state.tenantsLoaders.getCountLoader = false
            state.clearfilter = false
            state.initiateSearch = false
            state.listView.pagination = {
                ...state.listView.pagination,
                count: action.payload.data.count
            };
            state.panelView.pagination = {
                ...state.panelView.pagination,
                count: action.payload.data.count
            };
            state.filter.tenantsFilter = []
        },
        getTenantsCountFailure(state) {
            state.tenantsLoaders.getCountLoader = false
        },

        //get tenants list view details
        getTenantsListRequest(state) {
            state.tenantsLoaders.getListLoader = true
        },
        getTenantsListSuccess(state, action) {
            state.tenantsLoaders.getListLoader = false
            state.listView = {
                data: action.payload.data || [],
                pagination: {
                    ...state.listView.pagination,
                    ...action.payload.pagination
                }
            }
        },
        getTenantsListFailure(state) {
            state.tenantsLoaders.getListLoader = false
        },

        // get tenants details
        getTenantsDetailRequest(state) {
            state.tenantsLoaders.getDetailLoader = true
        },
        getTenantsDetailSuccess(state, action) {
            state.tenantsLoaders.getDetailLoader = false
            state.tenantDetail = action.payload
            const id = action.payload && action.payload.details && action.payload.details.id ? action.payload.details.id : 0;
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.approval_status = action.payload.details && action.payload.details.approval_status ? action.payload.details.approval_status : indexData.approval_status
                state.panelView.data[elementIndex] = indexData
            }
        },
        getTenantsDetailFailure(state) {
            state.tenantsLoaders.getDetailLoader = false
            state.tenantDetail = initialState.tenantDetail;
        },

        // update tenant approval status
        updateTenantStatusRequest(state) {
            state.tenantsLoaders.updateStatusLoader = true
        },
        updateTenantStatusSuccess(state, action) {
            state.tenantsLoaders.updateStatusLoader = false
            const id = action.payload.payload.tenant_id;
            const status = Number(action.payload.payload.approval_status);
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.approval_status = status
                state.panelView.data[elementIndex] = indexData
            }
        },
        updateTenantStatusFailure(state) {
            state.tenantsLoaders.updateStatusLoader = false
        },

        // update property manager flag
        updateTenantsFlagRequest(state) {
            state.isLoading = true;
        },
        updateTenantsFlagSuccess(state, action) {
            state.isLoading = false;
            const id = action.payload.payload.id;
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.is_flag = indexData.is_flag ? 0 : 1
                state.panelView.data[elementIndex] = indexData
            }
        },
        updateTenantsFlagFailure(state) {
            state.isLoading = false;
        },

        //update tenants view type
        updateTenantsViewType(state, action) {
            state.viewType = action.payload
        },

        downloadTenantsRequest(state, action) {
            state.fileName = `tenants_${moment().format("YYYY-MM-DD_h-mm-ssA")}.xlsx`
            state.isLoading = true
        },
        downloadTenantsSuccess(state, action) {
            state.isLoading = false
            const blob = new Blob([action.payload.data], { type: defaultExcelType });
            saveAs(blob, state.fileName || "report.xlsx");
            state.fileName = ''
        },
        downloadTenantsFailure(state) {
            state.isLoading = false
            state.fileName = ''
        },

        updateTenantsFilter(state, action) {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value
            }
            state.clearfilter = false
        },
        updateAllTenantsFilter(state, action) {
            state.filter = action.payload
            state.clearfilter = false
            state.initiateSearch = true
        },
        clearTenantsFilter(state) {
            state.filter = {
                userFilter: [],
                addressFilter: [],
                statusFilter: [],
                flagFilter: [],
                preferenceFilter: [],
                unitTypeFilter: [],
                affordabilityLevelFilter: [],
                tenantsFilter: []
            }
            state.clearfilter = true
        },
        updateOverallTenantsLoaders(state){
            state.overallTenantsLoader = Object.values(state.tenantsLoaders).some(loaderValue => loaderValue)
        }
    }
})

export const {
    getTenantsLeftPanelListRequest, getTenantsLeftPanelListSuccess, getTenantsLeftPanelListFailure,
    getTenantsCountRequest, getTenantsCountSuccess, getTenantsCountFailure,
    getTenantsListRequest, getTenantsListSuccess, getTenantsListFailure,
    getTenantsDetailRequest, getTenantsDetailSuccess, getTenantsDetailFailure,
    updateTenantStatusRequest, updateTenantStatusSuccess, updateTenantStatusFailure,
    updateTenantsFlagRequest, updateTenantsFlagSuccess, updateTenantsFlagFailure,
    updateTenantsViewType,
    downloadTenantsRequest, downloadTenantsSuccess, downloadTenantsFailure,
    updateTenantsFilter, clearTenantsFilter,updateAllTenantsFilter,
    updateOverallTenantsLoaders

} = tenantsSlice.actions;
export default tenantsSlice.reducer;