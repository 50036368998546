import { combineReducers } from 'redux';

import uiReducer from './uiReducer';
import authReducer from "./authReducer";
import profileReducer from './profileReducer';
import propertyManagerReducer from './propertyManagerReducer';
import globalFilterReducer from './globalFilterReducer';
import propertiesReducer from './propertiesReducer';
import globalReducer from './globalReducer';
import applicationsReducer from './applicationsReducer';
import runLotteryReducer from './runLotteryReducer';
import waitingListReducer from './waitingListReducer';
import tenantsReducer from './tenantsReducer';
import reportsReducer from './reportsReducer';
import webNotificationReducer from './webNotificationReducer';

const appReducer = combineReducers({
    ui: uiReducer,
    auth: authReducer,
    profile: profileReducer,
    propertyManager: propertyManagerReducer,
    globalFilter: globalFilterReducer,
    properties: propertiesReducer,
    global: globalReducer,
    applications: applicationsReducer,
    runLottery: runLotteryReducer,
    waitingList: waitingListReducer,
    tenants : tenantsReducer,
    reports: reportsReducer,
    webNotification: webNotificationReducer,
})

const rootReducer = (state, action) => {
    if (action.type === 'auth/logoutRequest') {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

export default rootReducer