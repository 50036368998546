import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Styles from './style'

//Components
import { Button, TextField, MultiSelect } from "../../components";

// reducer
import { updatePropertiesFilter, clearPropertiesFilter, revertPropertiesFilter } from "../../redux/reducer/propertiesReducer";

// helpers
import { defaultFlagOptions, preferenceOptions, defaultUnitTypeOptions, defaultHouseholdSizeOptions, affordabilityLevelOptions } from "../../helpers";
import Popover from "../../components/popover";

const FilterDrawer = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    // props
    const { openFilter, setOpenFilter, handleFilter, className } = props;

    // redux
    const { filter, clearfilter } = useSelector(s => (s.properties))

    //set temporary filters to existing filter value
    useEffect(() => {
        dispatch(revertPropertiesFilter())
    }, [])

    // call api after clearing the filter
    useEffect(() => {
        if (clearfilter) {
            handleFilter()
            setOpenFilter(false)
        }
    }, [clearfilter])

    return (
        <Popover
            anchorEl={openFilter}
            onClose={() => { setOpenFilter(false) }}
            className={className}
        >
            <Grid item xs={12} className="filterContainer">
                <Grid container>
                    <Grid container justifyContent={"space-between"}>
                        <Typography variant="h5" color="" className="fs-18">Filters</Typography>
                        <Typography
                            variant="body1"
                            color={"primary.dark"}
                            className="fw-500 pointer"
                            onClick={() => { dispatch(clearPropertiesFilter()) }}
                        >
                            Clear Filter
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="pt-3">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={4} sx={{ zIndex: 2 }}>
                                <Typography variant="caption" color="textSecondary">Unit Type</Typography>
                                <MultiSelect
                                    variant={"outlined"}
                                    id={"unitTypeFilter"}
                                    name={"unitTypeFilter"}
                                    placeholder={"Unit Type"}
                                    className={`sm`}
                                    options={defaultUnitTypeOptions}
                                    value={filter.unitTypeFilter}
                                    handleChange={(e) => { dispatch(updatePropertiesFilter(e)) }}
                                    initialValue={filter.unitTypeFilter}
                                    matchBy={"id"}
                                    display={"value"}
                                    limitTags={1}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} sx={{ zIndex: 1 }}>
                                <Typography variant="caption" color="textSecondary">Affordability Level</Typography>
                                <MultiSelect
                                    variant={"outlined"}
                                    id={"affordabilityLevelFilter"}
                                    name={"affordabilityLevelFilter"}
                                    placeholder={"Affordability Level"}
                                    className={`sm`}
                                    options={affordabilityLevelOptions}
                                    value={filter.affordabilityLevelFilter}
                                    handleChange={(e) => { dispatch(updatePropertiesFilter(e)) }}
                                    initialValue={filter.affordabilityLevelFilter}
                                    matchBy={"id"}
                                    display={"value"}
                                    limitTags={1}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} sx={{ zIndex: 1 }}>
                                <Typography variant="caption" color="textSecondary">Flagged</Typography>
                                <MultiSelect
                                    variant={"outlined"}
                                    id={"flagFilter"}
                                    name={"flagFilter"}
                                    placeholder={"Flagged"}
                                    className={`sm`}
                                    options={defaultFlagOptions}
                                    value={filter.flagFilter}
                                    handleChange={(e) => { dispatch(updatePropertiesFilter(e)) }}
                                    initialValue={filter.flagFilter}
                                    matchBy={"id"}
                                    display={"value"}
                                    limitTags={1}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} sx={{ zIndex: 1 }}>
                                <Typography variant="caption" color="textSecondary">Preference</Typography>
                                <MultiSelect
                                    variant={"outlined"}
                                    id={"preferenceFilter"}
                                    name={"preferenceFilter"}
                                    placeholder={"Preference"}
                                    className={`sm`}
                                    options={preferenceOptions}
                                    value={filter.preferenceFilter}
                                    handleChange={(e) => { dispatch(updatePropertiesFilter(e)) }}
                                    initialValue={filter.preferenceFilter}
                                    matchBy={"id"}
                                    display={"value"}
                                    limitTags={1}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="caption" color="textSecondary">Square Feet</Typography>
                                <Grid className="rangeValues sqFtRange">
                                    <TextField
                                        id={"minSqfeetFilter"}
                                        name={"minSqfeetFilter"}
                                        aria-label="Enter Minimum Square feet"
                                        placeholder={"Min"}
                                        value={filter.minSqfeetFilter}
                                        className={`sm ${filter.minSqfeetFilter ? '' : ''}`}
                                        type={"number"}
                                        handleChange={(e) => { dispatch(updatePropertiesFilter(e)) }}
                                    />
                                    <span style={{ padding: '8px 5px' }}>-</span>
                                    <TextField
                                        id={"maxSqfeetFilter"}
                                        name={"maxSqfeetFilter"}
                                        placeholder={"Max"}
                                        aria-label="Enter Maximum Square feet"
                                        value={filter.maxSqfeetFilter}
                                        className={`sm ${filter.maxSqfeetFilter ? '' : ''}`}
                                        type={"number"}
                                        handleChange={(e) => { dispatch(updatePropertiesFilter(e)) }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} sx={{ zIndex: 1 }}>
                                <Typography variant="caption" color="textSecondary">Household Size</Typography>
                                <MultiSelect
                                    variant={"outlined"}
                                    id={"householdSizeFilter"}
                                    name={"householdSizeFilter"}
                                    placeholder={"Household Size"}
                                    className={`sm`}
                                    options={defaultHouseholdSizeOptions}
                                    value={filter.householdSizeFilter}
                                    handleChange={(e) => { dispatch(updatePropertiesFilter(e)) }}
                                    initialValue={filter.householdSizeFilter}
                                    matchBy={"id"}
                                    display={"value"}
                                    limitTags={1}
                                />
                            </Grid>
                            <Grid item xs={12} align="right" sx={{ zIndex: 0 }}>
                                <Button variant="text" size="medium" className="mr-1" onClick={() => {
                                    setOpenFilter(false)
                                    dispatch(revertPropertiesFilter())
                                }} aria-label="Close filter">Close</Button>
                                <Button variant="contained" size="medium" color="primary" onClick={() => { handleFilter(setOpenFilter) }} aria-label="Apply filter">Apply</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Popover>
    );
}

// default props
FilterDrawer.defaultProps = {
    handleFilter: () => { },
    openFilter: false,
    setOpenFilter: () => { },
    className: ""
};

// prop types
FilterDrawer.propTypes = {
    handleFilter: PropTypes.func,
    openFilter: PropTypes.any,
    setOpenFilter: PropTypes.func,
    className: PropTypes.string
};

export default styled(FilterDrawer)(Styles);