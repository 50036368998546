import { createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import moment from 'moment';

// helpers
import { defaultExcelType } from '../../helpers';

const initialState = {
    isLoading: false,
    isLazyLoading: false,
    viewType: "panel",
    panelView: {
        data: [],
        pagination: {
            skip: 0,
            limit: 30,
            count: 0,
            sortField: "viewed",
            sortBy: 'asc'
        }
    },
    listView: {
        data: [],
        pagination: {
            skip: 0,
            limit: 9,
            count: 0,
            sortField: "name",
            sortBy: 'asc'
        }
    },
    currentApplication: {
        general: {
            unit_type: "",
            affordability_level: "",
            household_size: '',
            min_rent: '',
            max_rent: "",
            total_household_income: "",
            email: "",
            phone_number: "",
            alternate_phone: "",
            address: ['', ''],
            zip_code: null,
            race: null,
            ethnicity: null,
            ada: null,
            user_flags: [],
            user_views: [],
            applicant_id: "",
            approval_status: null
        },
        family_details: []
    },
    filter: {
        applicantsFilter: [],
        statusFilter : [],
        unitTypeFilter: [],
        affordabilityLevelFilter: [],
        flaggedFilter: [],
        preferenceFilter: [],
        applicationsFilter: []
    },
    tmpfilter: {
        applicantsFilter: [],
        statusFilter : [],
        unitTypeFilter: [],
        affordabilityLevelFilter: [],
        flaggedFilter: [],
        preferenceFilter: [],
        applicationsFilter: []
    },
    clearfilter : false,
    applicationsFileName: "",
    comments: [],
    logs:[],
    currentComment: {
        message: ""
    },
    applicationDocs: [],
    currentApplicationDocs: [],
    checkedValues:[],
    applicationsLoaders: {
        getLeftPanelListLoader: false,
        getListLoader: false,
        getCountLoader: false,
        getDetailLoader: false,
        approvalLoader: false,
    },
    overallApplicationsLoader: false
}

const applicationsSlice = createSlice({
    name: 'applications',
    initialState: initialState,
    reducers: {
        //get left panel details
        getApplicationsLeftPanelListRequest(state) {
            state.isLazyLoading = true;
            state.applicationsLoaders.getLeftPanelListLoader = true
        },
        getApplicationsLeftPanelListSuccess(state, action) {
            const isFirst = action.payload.pagination && action.payload.pagination.skip === 1 ? true : false
            state.panelView = {
                data: isFirst ? [...(action.payload.data || [])] : [...(state.panelView.data || []), ...(action.payload.data || [])],
                pagination: {
                    ...state.panelView.pagination,
                    ...action.payload.pagination
                }
            };
            state.isLazyLoading = false;
            state.applicationsLoaders.getLeftPanelListLoader = false
        },
        getApplicationsLeftPanelListFailure(state) {
            state.isLazyLoading = false;
            state.leftPanelList = initialState.leftPanelList;
            state.applicationsLoaders.getLeftPanelListLoader = false
        },

        //get the total count of applications for pagination
        getApplicationsCountRequest(state) {
            state.applicationsLoaders.getCountLoader = true
        },
        getApplicationsCountSuccess(state, action) {
            state.clearfilter = false
            state.listView.pagination = {
                ...state.listView.pagination,
                count: action.payload.data.count
            };
            state.panelView.pagination = {
                ...state.panelView.pagination,
                count: action.payload.data.count
            };
            state.applicationsLoaders.getCountLoader = false
        },
        getApplicationsCountFailure(state) {
            state.applicationsLoaders.getCountLoader = false
        },

        // get applications details
        getApplicationsDetailRequest(state) {
            state.applicationsLoaders.getDetailLoader = true
        },
        getApplicationsDetailSuccess(state, action) {
            state.applicationsLoaders.getDetailLoader = false
            state.currentApplication.general = action.payload.applicant_details
            state.currentApplication.family_details = action.payload.family_details
            const id = action.payload.applicant_details.id;
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.approval_status = action.payload.applicant_details ? action.payload.applicant_details.approval_status : indexData.approval_status
                state.panelView.data[elementIndex] = indexData
            }
        },
        getApplicationsDetailFailure(state) {
            state.applicationsLoaders.getDetailLoader = false
            state.currentProperty.general = initialState.currentProperty.general;
        },

        // update applications approval status
        applicationsApprovalRequest(state) {
            state.applicationsLoaders.approvalLoader = true
        },
        applicationsApprovalSuccess(state, action) {
            state.applicationsLoaders.approvalLoader = false
            const id = action.payload.payload.application_id;
            const status = Number(action.payload.payload.approval_status);
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.approval_status = status
                state.panelView.data[elementIndex] = indexData
            }
            state.currentApplication.approval_status = status
        },
        applicationsApprovalFailure(state) {
            state.applicationsLoaders.approvalLoader = false
        },

        // update applications flag
        updateApplicationsFlagRequest(state) {
            state.isLoading = true;
        },
        updateApplicationsFlagSuccess(state, action) {
            state.isLoading = false;
            const id = action.payload.payload.id;
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.is_flag = indexData.is_flag ? 0 : 1
                state.panelView.data[elementIndex] = indexData
            }
        },
        updateApplicationsFlagFailure(state) {
            state.isLoading = false;
        },

        // update applicants filter
        updateApplicantsFilter(state, action) {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value
            }
            state.clearfilter = false
        },

        // Revert filter to temporary filter values
        revertApplicationsFilter(state, action){
            state.filter = {
                ...state.tmpfilter
            }
        },

        //update temporary filter
        updateTempApplicationsFilter(state, action){
            state.tmpfilter = {
                ...state.filter
            }
        },

        clearApplicationsFilter(state) {
            state.filter = {
            applicantsFilter: [],
            statusFilter: [],
            unitTypeFilter: [],
            affordabilityLevelFilter: [],
            flaggedFilter: [],
            preferenceFilter: [],
            }
            state.clearfilter = true
        },

        //get applications list view details
        getApplicationsListRequest(state) {
            state.applicationsLoaders.getListLoader = true
        },
        getApplicationsListSuccess(state, action) {
            state.applicationsLoaders.getListLoader = false
            state.listView = {
                data: action.payload.data || [],
                pagination: {
                    ...state.listView.pagination,
                    ...action.payload.pagination
                }
            }
            state.checkedValues = []
        },
        getApplicationsListFailure(state) {
            state.applicationsLoaders.getListLoader = false
        },

        // update applications manager view type
        updateApplicationsViewType(state, action) {
            state.viewType = action.payload
        },

        //download applications list
        downloadApplicationsRequest(state, action) {
            state.applicationsFileName = `applications_${moment().format("YYYY-MM-DD_h-mm-ssA")}.xlsx`
            state.isLoading = true
        },
        downloadApplicationsSuccess(state, action) {
            state.isLoading = false
            const blob = new Blob([action.payload.data], { type: defaultExcelType });
            saveAs(blob, state.applicationsFileName || "report.xlsx");
            state.applicationsFileName = ''
        },
        downloadApplicationsFailure(state) {
            state.isLoading = false
            state.applicationsFileName = ''
        },

        //get applications comments
        getApplicationsCommentsRequest(state) {
            state.isLoading = true
            state.comments = []
        },
        getApplicationsCommentsSuccess(state, action) {
            state.isLoading = false
            state.comments = action.payload || []
        },
        getApplicationsCommentsFailure(state) {
            state.isLoading = false
            state.comments = []
        },

        getApplicationsLogsRequest(state) {
            state.isLoading = true
            state.logs = initialState.logs
        },
        getApplicationsLogsSuccess(state, action) {
            state.isLoading = false
            state.logs = action.payload || []
        },
        getApplicationsLogsFailure(state) {
            state.isLoading = false
            state.logs = initialState.logs
        },


        //update applications comments
        updateApplicationsCommentsRequest(state) {
            state.isLoading = true
        },
        updateApplicationsCommentsSuccess(state) {
            state.isLoading = false
            state.currentComment = {
                message: ""
            }
        },
        updateApplicationsCommentsFailure(state) {
            state.isLoading = false
        },

        // update comment text
        updateApplicationsCurrentComment(state, action) {
            state.currentComment = {
                message: action.payload
            }
        },

        //delete applications comments
        deleteApplicationsCommentRequest(state) {
            state.isLoading = true
        },
        deleteApplicationsCommentSuccess(state, action) {
            state.isLoading = false
            const id = action.payload.payload.id;
            const elementIndex = state.comments.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                state.comments.splice(elementIndex, 1)
            }

        },
        deleteApplicationsCommentFailure(state, action) {
            state.isLoading = false
            state.applicationDocs = action.payload.data
        },

        //get application documents
        getApplicationsDocumentsRequest(state){
            state.isLoading = true
        },
        getApplicationsDocumentsSuccess(state, action){
            state.isLoading = false
            state.applicationDocs = action.payload
        },
        getApplicationsDocumentsFailure(state){
            state.isLoading = false
        },

        //upload application documents
        uploadApplicationsDocumentsRequest(state){
            state.isLoading = true
        },
        uploadApplicationsDocumentsSuccess(state){
            state.isLoading = false
            state.currentApplicationDocs = []
        },
        uploadApplicationsDocumentsFailure(state){
            state.isLoading = false
        },

        //setting current documents
        setCurrentDocuments(state, action){
            state.currentApplicationDocs = action.payload
        },

        // reject application
        rejectApplicationRequest(state) {
            state.isLoading = true
        },
        rejectApplicationSuccess(state, action) {
            const id = action.payload.application_id;
            const approvalStatus = Number(action.payload.approval_status)
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.approval_status = approvalStatus
                state.panelView.data[elementIndex] = indexData
            }
            state.currentApplication.general.approval_status = approvalStatus
            state.isLoading = false
        },
        rejectApplicationFailure(state) {
            state.isLoading = false
        },

        updateDownloadCheckedValues(state,action){
            let idList = [...state.checkedValues];
            const curVal = action.payload.id;
            const curValIndex = idList.findIndex(f => (f.id === curVal));
            if (curValIndex > -1) {
                idList.splice(curValIndex, 1)
            } else {
                idList.push(action.payload)
            }
            state.checkedValues = idList
        },
        updateDownloadAllCheckedValues(state,action){
            if(state.checkedValues.length === action.payload.length){
                state.checkedValues = []
            }else{
                state.checkedValues = [...action.payload]
            }
        },
        updateOverallApplicationsLoaders(state){
            state.overallApplicationsLoader = Object.values(state.applicationsLoaders).some(loaderValue => loaderValue)
        }
    }
})

export const {
    getApplicationsLeftPanelListRequest, getApplicationsLeftPanelListSuccess, getApplicationsLeftPanelListFailure,
    getApplicationsCountRequest, getApplicationsCountSuccess, getApplicationsCountFailure,
    getApplicationsDetailRequest, getApplicationsDetailSuccess, getApplicationsDetailFailure,
    applicationsApprovalRequest, applicationsApprovalSuccess, applicationsApprovalFailure,
    updateApplicationsFlagRequest, updateApplicationsFlagSuccess, updateApplicationsFlagFailure,
    updateApplicantsFilter, clearApplicationsFilter, updateTempApplicationsFilter, revertApplicationsFilter,
    updateApplicationsViewType,
    getApplicationsListRequest, getApplicationsListSuccess, getApplicationsListFailure,
    downloadApplicationsRequest, downloadApplicationsSuccess, downloadApplicationsFailure,
    getApplicationsCommentsRequest, getApplicationsCommentsSuccess, getApplicationsCommentsFailure,
    getApplicationsLogsRequest, getApplicationsLogsSuccess, getApplicationsLogsFailure,
    updateApplicationsCommentsRequest, updateApplicationsCommentsSuccess, updateApplicationsCommentsFailure,
    deleteApplicationsCommentRequest, deleteApplicationsCommentSuccess, deleteApplicationsCommentFailure,
    updateApplicationsCurrentComment,
    getApplicationsDocumentsRequest, getApplicationsDocumentsSuccess, getApplicationsDocumentsFailure,
    uploadApplicationsDocumentsRequest, uploadApplicationsDocumentsSuccess, uploadApplicationsDocumentsFailure,
    setCurrentDocuments,
    rejectApplicationRequest, rejectApplicationSuccess, rejectApplicationFailure,
    updateDownloadCheckedValues, updateDownloadAllCheckedValues,
    updateOverallApplicationsLoaders
} = applicationsSlice.actions
export default applicationsSlice.reducer