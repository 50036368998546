import { AuthRoute, PrivateRoute, SignupRoute, PublicRoute } from "../authRoutes";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";

// import containers
import Login from "./authentication/login";
import Signup from "./authentication/signup";
import ForgotPassword from "./authentication/forgotPassword";
import ResetPassword from "./authentication/resetPassword";
import Success from "./authentication/success";
import Profile from "./profile";
import UserActivation from "./authentication/userActivation";
import Properties from "./properties/properties.router";
import PropertyManager from "./propertyManager/propertyManager.router";
import Application from "./application/application.router";
import Obligation from "./policies/obligation";
import WaitingList from "./waitingList/listView";
import incomeAndRent from "./policies/incomeAndRent";
import Tenants from "./tenants/tenants.router";
import Notification from "./notifications";
import Reports from "./reports/listView";
import NotFound from "./NotFound";
import References from "./references/references.router";
// import MigrateFileInput from "./migration";
// import ImageMigrate from "./imagemigration";
import { useSelector } from "react-redux";
import forgotPassWall from '../assets/images/city_staff_forgot_pass_wall.png'
import { useEffect } from "react";

const AppRoutes = () => {
    const myUrl = process.env.REACT_APP_S3_URL;
    const path = `${myUrl}assets/`

    const { loggedUser: { user_group_id } } = useSelector(s => (s.profile))

    //variables
    useEffect(() => {
        if (user_group_id) {
            localStorage.setItem('user_group_id', user_group_id);
        }
    }, [user_group_id])

    const isPropertyManager = localStorage.getItem('user_group_id') == 2
    //console.log(`isPropertyManager`,isPropertyManager);


    return (
        <HashRouter>
            <Routes>
                {/* auth routes */}
                <Route exact path="/" element={<AuthRoute component={Login} />} />
                <Route exact path="/login" element={<AuthRoute component={Login} />} />
                <Route exact path="/signup" element={<SignupRoute component={Signup} />} />
                <Route exact path="/forgotpassword" element={<AuthRoute srcImage={forgotPassWall} component={ForgotPassword} />} />
                <Route exact path="/reset-password/:uidb64/:hash" element={<AuthRoute srcImage={forgotPassWall} component={ResetPassword} />} />
                <Route exact path="/create-password/:uidb64/:hash" element={<AuthRoute srcImage={forgotPassWall} component={ResetPassword} />} />
                <Route exact path="/activate-user/:uidb64/:hash" element={<SignupRoute component={UserActivation} />} />
                <Route exact path="/success" element={<SignupRoute component={Success} />} />

                {/* private routes */}
                <Route exact path="/properties/*" element={<PrivateRoute component={Properties} />} />
                <Route exact path="/profile" element={<PrivateRoute component={Profile} />} />
                <Route path="/property-manager/*" element={<PrivateRoute component={PropertyManager} />} />
                <Route path="/applications/*" element={<PrivateRoute component={Application} />} />
                <Route path="/tenants/*" element={<PrivateRoute component={Tenants} />} />
                <Route path="/obligation" element={<PrivateRoute component={Obligation} />} />
                <Route path="/waiting-list" element={isPropertyManager? <PrivateRoute component={WaitingList} /> : <Navigate to="/applications"></Navigate>} />
                <Route path="/income-rent" element={<PrivateRoute component={incomeAndRent} />} />
                <Route path="/notification" element={<PrivateRoute component={Notification} />} />
                <Route path="/reports" element={<PrivateRoute component={Reports} />} />
                <Route path="/references/*" element={<PrivateRoute component={References} />} />
                {/* <Route path="/migrate-data" element={<PublicRoute component={MigrateFileInput}/>} />
                <Route path="/migrate-images" element={<PublicRoute component={ImageMigrate}/>} /> */}
                <Route path='*' element={<PublicRoute component={NotFound} />} />
            </Routes>
        </HashRouter>
    );
};

export default AppRoutes;