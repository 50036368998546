import React, { useState, useEffect } from 'react';
import { Grid, Typography, Checkbox, FormControlLabel, MenuItem, TextField, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from '../../components';
import { useSelector } from 'react-redux';

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3),
    '& .MuiTypography-h6': {
      marginBottom: theme.spacing(2),
    },
    '& .unitType': {
      marginBottom: theme.spacing(2),
    },
    '& .reasonDropdown': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    '& .actionButtons': {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: theme.spacing(2),
    },
}));

const ChooseUnits = ({ open, onClose, onSubmit }) => {
  const { units } = useSelector(state => state.properties.currentProperty);
  const [groupedUnits, setGroupedUnits] = useState({});
  const [selectedUnits, setSelectedUnits] = useState({});
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');

  useEffect(() => {
    const groupUnits = () => {
      const grouped = units.reduce((acc, unit) => {
        const unitTypeName = getUnitTypeName(unit.unit_type);
        if (!acc[unitTypeName]) {
          acc[unitTypeName] = [];
        }
        unit.unitDetails.forEach(detail => {
          acc[unitTypeName].push({
            unit_no: detail.unit_no,
            id: detail.id,
            unit_id: unit.id,
            unit_detail_id: detail.id,
            status: detail.status,
            lottery: detail.lottery,
            tenant: detail.tenant,
          });
        });
        return acc;
      }, {});
      setGroupedUnits(grouped);

      // Initialize selectedUnits based on current status
      const selected = Object.entries(grouped).reduce((acc, [unitType, units]) => {
        acc[unitType] = units.reduce((unitAcc, unit) => {
          unitAcc[unit.unit_no] = unit.status && unit.lottery;
          return unitAcc;
        }, {});
        return acc;
      }, {});
      setSelectedUnits(selected);
    };

    groupUnits();
  }, [units]);

  const reasons = [
    'Not Satisfied with the Applications',
    'Waiting List Exhausted',
    'Technical Error in Previous Run',
    'Compliance Violation',
    'Insufficient Qualified Applications',
    'Duplicate Entries Found',
    'Manual Override Required',
    'Policy Change',
    'Other',
  ];

  const getUnitTypeName = (unitTypeId) => {
    const unitTypeMap = {
      7: 'Studio',
      1: '1-Bedroom',
      2: '2-Bedroom',
      3: '3-Bedroom',
      4: '4-Bedroom',
    };
    return unitTypeMap[unitTypeId] || 'Unknown';
  };

  const handleUnitChange = (unitType, unitNumber) => {
    setSelectedUnits(prev => ({
      ...prev,
      [unitType]: {
        ...prev[unitType],
        [unitNumber]: !prev[unitType][unitNumber]
      }
    }));
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = () => {
    const finalReason = reason === 'Other' ? otherReason : reason;
    const updatedUnits = Object.entries(groupedUnits).flatMap(([unitType, units]) => 
      units.map(unit => ({
        ...unit,
        status: selectedUnits[unitType][unit.unit_no]
      }))
    );
    onSubmit({ updatedUnits, reason: finalReason });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <StyledGrid container direction="column">
        <Typography variant="h6">Select Units To Re-Run Lottery</Typography>
        {Object.entries(groupedUnits).map(([unitType, units]) => (
          <Grid item key={unitType} className="unitType">
            <Typography variant="subtitle1">{unitType}</Typography>
            <Grid container>
              {units.map(unit => (
                <Grid item xs={3} key={`${unitType}-${unit.unit_no}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUnits[unitType]?.[unit.unit_no] || false}
                        onChange={() => handleUnitChange(unitType, unit.unit_no)}
                        disabled={(unit.status && unit.lottery) || !unit.status }
                        color="primary"
                      />
                    }
                    label={unit.unit_no}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
        <TextField
          select
          fullWidth
          className="reasonDropdown"
          value={reason}
          onChange={handleReasonChange}
          label="Select Reason To Re-Run The Lottery"
          placeholder="Select Reason To Re-Run The Lottery"
        >
          {reasons.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        {reason === 'Other' && (
          <TextField
            fullWidth
            multiline
            rows={3}
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
            label="Please specify the reason"
            placeholder="Enter your reason here"
            className="otherReasonInput"
            sx={{ marginBottom: 2 }}
          />
        )}
        <Grid item className="actionButtons">
          <Button
            variant="text"
            color="primary"
            onClick={onClose}
            size="medium"
            sx={{ background: '#E5E5E5', padding: '6px 14px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            size="medium"
            disabled={!reason || (reason === 'Other' && !otherReason)}
          >
            Submit
          </Button>
        </Grid>
      </StyledGrid>
    </Dialog>
  );
};

export default ChooseUnits;