import { createSlice, current } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import moment from 'moment';

// helpers
import { defaultUnit, jsonParser, defaultExcelType, defaultUnitDetails, propertiesGeneralComp, propertiesAmenitiesComp, propertiesUnitsComp } from '../../helpers';

const initialState = {
    isLoading: false,
    isLazyLoading:false,
    viewType: "panel",
    welcomeData: {},
    propertyReport: {},
    panelView: {
        data: [],
        pagination: {
            skip: 0,
            limit: 30,
            count: 0,
            sortField: "viewed",
            sortBy: 'asc'
        }
    },
    clearfilter: false,
    listView: {
        data: [],
        pagination: {
            skip: 0,
            limit: 9,
            count: 0,
            sortField: "name",
            sortBy: 'asc'
        }
    },
    logs: [],
    currentProperty: {
        general: {
            name: "",
            desc: "",
            status: "1",
            address: "",
            state: "",
            city: "",
            zipcode: "",
            lat_long: [null, null],
            built_year: null,
            available_at: null,
            offer_valid_at: null,
            application_date: null,
            application_deadline: null,
            lottery_date: null,
            preference: "4",
            id: null,
            user_flags: [],
            report_period: "0",
            is_ada:null,
            ami_rmi:"0"
        },
        amenities: [],
        photos: [],
        units: [
            { ...jsonParser(defaultUnit) }
        ]
    },
    propertyDBData:{
        general : {},
        units:[],
        amenities:[]
    },
    isDetailEdited: false,
    isUnitsEdited: false,
    unitPhotos: {},
    updatedUnitIds: {},
    comments: [],
    currentComment: {
        message: ""
    },
    propertiesFileName: "",
    filter: {
        managerFilter: [],
        propertiesFilter: [],
        statusFilter: [],
        addressFilter: [],
        flagFilter: [],
        unitTypeFilter: [],
        affordabilityLevelFilter: [],
        preferenceFilter: [],
        householdSizeFilter: [],
        startDate: '',
        endDate: '',
        minSqfeetFilter: '',
        maxSqfeetFilter: ''
    },
    tmpfilter: {
        managerFilter: [],
        propertiesFilter: [],
        statusFilter: [],
        addressFilter: [],
        flagFilter: [],
        unitTypeFilter: [],
        affordabilityLevelFilter: [],
        preferenceFilter: [],
        householdSizeFilter: [],
        startDate: '',
        endDate: '',
        sqfeetFilter: '',
    },
    propertySubmit: false,
    unitsSubmit: false,

    // track changes
    hasGeneralChanges : false,
    hasAmenityChanges : false,
    hasPhotosChange : false,
    hasUnitChanges : false,
    hasUnitPhotosChanges : false,
    changedFields:[],
    unitChangedFields:[],
    propertiesLoaders: {
        welcomeLoader: false,
        getLeftPanelLoader: false,
        getCountLoader: false,
        getListLoader: false,
        getDetailLoader: false,
        getUnitsLoader: false,
        getPhotosLoader: false,
        getAmenitiesLoader: false,
        updateGeneralLoader: false,
        updateAmenitiesLoader: false,
        updatePhotosLoader: false,
        updateUnitsLoader: false,
        updateUnitPhotosLoader: false,
        updateFlagsLoader: false,
        updateStatusLoader: false,
        updateUnitsStatusLoader: false,
        approvalLoader:false,
    },
    overallPropertiesLoaders: false,
}

const propertiesSlice = createSlice({
    name: 'properties',
    initialState: initialState,
    reducers: {
        //update property manager comments
        getWelcomePageRequest(state) {
            state.propertiesLoaders.welcomeLoader = true
        },
        getWelcomePageSuccess(state, action) {
            state.propertiesLoaders.welcomeLoader = false
            state.welcomeData = action.payload
        },
        getWelcomePageFailure(state) {
            state.propertiesLoaders.welcomeLoader = false
        },

        //general
        updateGeneral(state, action) {
            state.currentProperty.general = action.payload
            // check for changes on the data
            state.isDetailEdited = true
            const { hasChanges, changedFields } = propertiesGeneralComp(current(state.propertyDBData.general), action.payload)
            state.changedFields = changedFields
            state.hasGeneralChanges = hasChanges
        },
        //amenities
        updateAmenities(state, action) {
            state.currentProperty.amenities = action.payload
            state.isDetailEdited = true
            state.hasGeneralChanges = propertiesAmenitiesComp(current(state.propertyDBData.amenities), action.payload)
            state.hasAmenityChanges = true
        },
        //photos
        updatePhotos(state, action) {
            state.currentProperty.photos = [...state.currentProperty.photos, ...action.payload]
            state.isDetailEdited = true
            state.hasPhotosChange = true 
        },
        deletePhotos(state, action) {
            let tempPhotos = [...state.currentProperty.photos]
            tempPhotos.splice(action.payload, 1)
            state.currentProperty.photos = tempPhotos
            state.isDetailEdited = true
            state.hasPhotosChange = true
        },

        // units update
        updateUnits(state, action) {
            state.currentProperty.units = action.payload
            state.isUnitsEdited = true
            const { hasChanges, changedFields } = propertiesUnitsComp(current(state.propertyDBData.units), action.payload)
            state.hasUnitChanges = hasChanges
            state.unitChangedFields = changedFields
        },
        // update unit count and its dropdown
        updateUnitCount(state, action) {
            let { data: unitData, index: unitIndex, photos: unitDetailPhotos } = action.payload
            const unitCount = Number(unitData[unitIndex].unit_count);
            const unitDetailLength = unitData[unitIndex].unitDetails.length;
            if (unitCount !== unitDetailLength) {
                if (!unitCount) {
                    unitData[unitIndex].unitDetails = []
                    let tmpPhotos = {...unitDetailPhotos}
                    for(let i = 0; i< unitDetailLength; i++){
                        let indexingVariable = `${unitIndex}_${i}`
                        delete tmpPhotos[indexingVariable]  
                    }
                    unitDetailPhotos = tmpPhotos
                } else if (unitCount > unitDetailLength) {
                    let tmpData = [...unitData[unitIndex].unitDetails]
                    for (let i = unitDetailLength; i < unitCount; i++) {
                        tmpData.push({ ...jsonParser(defaultUnitDetails) })
                    }
                    unitData[unitIndex].unitDetails = tmpData
                } else if (unitCount < unitDetailLength) {
                    let tmpData = [...unitData[unitIndex].unitDetails]
                    tmpData = tmpData.splice(0, unitCount)
                    unitData[unitIndex].unitDetails = tmpData
                    let tmpPhotos = {...unitDetailPhotos}
                    for(let i = unitCount ; i< unitDetailLength; i++){
                        let indexingVariable = `${unitIndex}_${i}`
                        delete tmpPhotos[indexingVariable]  
                    }
                    unitDetailPhotos = tmpPhotos
                } else {
                    unitData[unitIndex].unitDetails = [{ ...jsonParser(defaultUnitDetails) }]
                }
            }
            state.currentProperty.units = unitData;
            state.unitPhotos = unitDetailPhotos
            state.isUnitsEdited = true
            const { hasChanges, changedFields } = propertiesUnitsComp(current(state.propertyDBData.units), unitData)
            state.hasUnitChanges = hasChanges
            state.unitChangedFields = changedFields
        },
        // update unit details
        updateUnitDetails(state, action) {
            let tmpUnits = [...state.currentProperty.units];
            const { data, unitIndex, detailIndex } = action.payload;
            let indexUnitData = tmpUnits[unitIndex];
            let indexUnitDetails = indexUnitData.unitDetails[detailIndex]
            indexUnitDetails = { ...indexUnitDetails, ...data }
            tmpUnits[unitIndex].unitDetails[detailIndex] = indexUnitDetails;
            state.currentProperty.units = tmpUnits
            state.isUnitsEdited = true
            const { hasChanges, changedFields } = propertiesUnitsComp(current(state.propertyDBData.units), tmpUnits)
            state.hasUnitChanges = hasChanges
            state.unitChangedFields = changedFields
        },

        // add new unit
        addNewUnit(state) {
            state.currentProperty.units.push({ ...jsonParser(defaultUnit) })
            state.isUnitsEdited = true
            state.hasUnitChanges = true
        },

        addSubUnit(state, action) {
            const addIndex = action.payload;
            if(state.currentProperty.units[addIndex]){
                const tmpUnit = {...state.currentProperty.units[addIndex]}
                tmpUnit.unit_count = tmpUnit.unit_count + 1
                tmpUnit.unitDetails = [...tmpUnit.unitDetails, {...jsonParser(defaultUnitDetails)} ]
                state.currentProperty.units[addIndex] = tmpUnit
                state.isUnitsEdited = true
                state.hasUnitChanges = true
            }
        },

        // delete unit
        deleteUnit(state, action) {
            const {index: delIndex, units: units}  = action.payload
            let tmpUnits = [...state.currentProperty.units];
            tmpUnits.splice(delIndex, 1)
            state.currentProperty.units = tmpUnits
            let tmpPhotos = {...state.unitPhotos}
            for(let i = 0 ; i< units[delIndex].unitDetails.length; i++){
                let indexingVariable = `${delIndex}_${i}`
                delete tmpPhotos[indexingVariable]  
            }
            for(let i = delIndex + 1; i< units.length; i++){
                for(let j = 0; j< units[i].unitDetails.length; j++){
                    tmpPhotos[`${i-1}_${j}`] = tmpPhotos[`${i}_${j}`]
                    delete tmpPhotos[`${i}_${j}`]
                }
            }
            state.unitPhotos = tmpPhotos
            state.isUnitsEdited = true
            state.hasUnitChanges = true
        },

        // delete unit details
        deleteUnitDetail(state, action) {
            const { index, itemIndex, unitDetail } = action.payload
            let tmpUnits = [...state.currentProperty.units];
            tmpUnits[index].unitDetails.splice(itemIndex, 1)
            tmpUnits[index].unit_count = tmpUnits[index].unit_count - 1
            let tmpPhotos = {...state.unitPhotos}
            delete tmpPhotos[`${index}_${itemIndex}`]
            for(let i=itemIndex+1; i< unitDetail.length; i++){
                tmpPhotos[`${index}_${i-1}`] = tmpPhotos[`${index}_${i}`]
                delete tmpPhotos[`${index}_${i}`]
            }
            state.unitPhotos = tmpPhotos
            state.currentProperty.units = tmpUnits
            state.isUnitsEdited = true
            state.hasUnitChanges = true
        },

        //get properties list view details
        getPropertiesListRequest(state) {
            state.propertiesLoaders.getListLoader = true
        },
        getPropertiesListSuccess(state, action) {
            state.propertiesLoaders.getListLoader = false
            state.listView = {
                data: action.payload.data || [],
                pagination: {
                    ...state.listView.pagination,
                    ...action.payload.pagination
                }
            }
        },
        getPropertiesListFailure(state) {
            state.propertiesLoaders.getListLoader = false
        },

        //get the total count of properties for pagination
        getPropertiesCountRequest(state) {
            state.propertiesLoaders.getCountLoader = true
            state.propertySubmit = false;
            state.unitsSubmit = false;
        },
        getPropertiesCountSuccess(state, action) {
            state.propertiesLoaders.getCountLoader = false
            state.clearfilter = false
            state.listView.pagination = {
                ...state.listView.pagination,
                count: action.payload.data.count
            };
            state.panelView.pagination = {
                ...state.panelView.pagination,
                count: action.payload.data.count
            };
        },
        getPropertiesCountFailure(state) {
            state.propertiesLoaders.getCountLoader = false
        },

        // create or update property genera
        updateGeneralRequest(state) {
            state.propertiesLoaders.updateGeneralLoader = true
        },
        updateGeneralSuccess(state, action) {
            if (action.payload.data && action.payload.data && action.payload.data.id) {
                state.currentProperty.general = { ...state.currentProperty.general, id: action.payload.data.id }
            }
            if (action.payload.payload.general.approval_status === 1) {
                const id = action.payload.payload.general.id;
                const status = (action.payload.data.approval_status || action.payload.data.approval_status === 0? action.payload.data.approval_status : action.payload.payload.general.approval_status)
                const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
                if (elementIndex >= 0) {
                    let indexData = state.panelView.data[elementIndex];
                    indexData.approval_status = status
                    state.panelView.data[elementIndex] = indexData
                }
                state.currentProperty.general = { ...state.currentProperty.general, approval_status: status }
                state.changedFields = []
            }
            state.propertiesLoaders.updateGeneralLoader = false
            state.propertySubmit = true;
            state.isDetailEdited = false;
            state.hasGeneralChanges = false;
        },
        updateGeneralFailure(state) {
            state.propertiesLoaders.updateGeneralLoader = false
        },

        // create or update amenities
        updateAmenitiesRequest(state) {
            state.propertiesLoaders.updateAmenitiesLoader = true
        },
        updateAmenitiesSuccess(state) {
            state.propertiesLoaders.updateAmenitiesLoader = false
            state.isDetailEdited = false
            state.hasAmenityChanges = false;
        },
        updateAmenitiesFailure(state) {
            state.propertiesLoaders.updateAmenitiesLoader = false
        },

        // create or update photos
        updatePhotosRequest(state) {
            state.propertiesLoaders.updatePhotosLoader = true
        },
        updatePhotosSuccess(state, action) {
            state.propertiesLoaders.updatePhotosLoader = false
            state.currentProperty.photos = action.payload
            state.isDetailEdited = false
            state.hasPhotosChange = false;
        },
        updatePhotosFailure(state) {
            state.propertiesLoaders.updatePhotosLoader = false
        },

        // update property approval status
        propertiesApprovalRequest(state) {
            state.propertiesLoaders.approvalLoader = true
        },
        propertiesApprovalSuccess(state, action) {
            state.propertiesLoaders.approvalLoader = false
            const id = action.payload.payload.property_id;
            const status = Number(action.payload.payload.approval_status);
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.approval_status = status
                state.panelView.data[elementIndex] = indexData
            }
            state.currentProperty.general.approval_status = status
        },
        propertiesApprovalFailure(state) {
            state.propertiesLoaders.approvalLoader = false
        },

        // get list of properties for left panel
        getPropertiesLeftPanelListRequest(state) {
            state.isLazyLoading = true;
            state.propertiesLoaders.getLeftPanelLoader = true
        },
        getPropertiesLeftPanelListSuccess(state, action) {
            const isFirst = action.payload.pagination && action.payload.pagination.skip === 1 ? true : false
            state.panelView = {
                data: isFirst ? [...(action.payload.data || [])] : [...(state.panelView.data || []), ...(action.payload.data || [])],
                pagination: {
                    ...state.panelView.pagination,
                    ...action.payload.pagination
                }
            };
            state.isLazyLoading = false;
            state.propertiesLoaders.getLeftPanelLoader = false
        },
        getPropertiesLeftPanelListFailure(state) {
            state.isLazyLoading = false;
            state.propertiesLoaders.getLeftPanelLoader = false
            state.leftPanelList = initialState.leftPanelList;
        },

        // get properties details
        getPropertiesDetailRequest(state) {
            state.propertiesLoaders.getDetailLoader = true;
        },
        getPropertiesDetailSuccess(state, action) {
            state.propertiesLoaders.getDetailLoader = false;
            state.currentProperty.general = action.payload

            //For checking field update purpose
            state.propertyDBData.general = action.payload

            const id = action.payload.id;
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.approval_status = action.payload ? action.payload.approval_status : indexData.approval_status
                state.panelView.data[elementIndex] = indexData
            }
            state.isDetailEdited = false
            state.hasGeneralChanges = false;
            state.hasAmenityChanges = false;
            state.hasPhotosChange = false;
        },
        getPropertiesDetailFailure(state) {
            state.propertiesLoaders.getDetailLoader = false;
            state.currentProperty.general = initialState.currentProperty.general;
        },

        // update properties flag
        updatePropertiesFlagRequest(state) {
            state.propertiesLoaders.updateFlagsLoader = true;
        },
        updatePropertiesFlagSuccess(state, action) {
            state.propertiesLoaders.updateFlagsLoader = false;
            const id = action.payload.payload.id;
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.is_flag = indexData.is_flag ? 0 : 1
                state.panelView.data[elementIndex] = indexData
            }
        },
        updatePropertiesFlagFailure(state) {
            state.propertiesLoaders.updateFlagsLoader = false;
        },

        // update property rent details
        updateUnitsRequest(state) {
            state.propertiesLoaders.updateUnitsLoader = true;
        },
        updateUnitsSuccess(state, action) {
            const resData = action.payload.data && action.payload.data.ids ? action.payload.data.ids : {}
            const payload = action.payload.payload || {}
            state.updatedUnitIds = resData;
            if (resData && Object.keys(resData).length) {
                let units = state.currentProperty.units;
                for (let i = 0; i < units.length; i++) {
                    if (resData[`${i}`]) {
                        units[i].id = Number(resData[`${i}`])
                    }

                    if (units[i].unitDetails) {
                        for (let j = 0; j < units[i].unitDetails.length; j++) {
                            if (resData[`${i}_${j}`]) {
                                units[i].unitDetails[j].id = Number(resData[`${i}_${j}`])
                            }
                        }
                    }
                }
                state.currentProperty.units = units

                // update track data
                state.propertyDBData.units = units
                state.unitChangedFields = []
            }

            const status = action.payload.data.approval_status || action.payload.data.approval_status >=0 ? action.payload.data.approval_status : payload.approvalStatus
            if (status) {
                const id = payload.general.id;
                const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
                if (elementIndex >= 0) {
                    let indexData = state.panelView.data[elementIndex];
                    indexData.approval_status = status
                    state.panelView.data[elementIndex] = indexData
                }
                state.currentProperty.general = { ...state.currentProperty.general, approval_status: status }
            }
            state.propertiesLoaders.updateUnitsLoader = false;
            state.unitsSubmit = true;
            state.isUnitsEdited = false;
            state.hasUnitChanges = false
            state.hasUnitPhotosChanges = false
        },
        updateUnitsFailure(state, action) {
            const resData = action.payload && action.payload.response && action.payload.response && action.payload.response.data && action.payload.response.data.message && action.payload.response.data.message.data ? action.payload.response.data.message.data : {}
            state.updatedUnitIds = resData;
            if (resData && Object.keys(resData).length) {
                let units = state.currentProperty.units;
                for (let i = 0; i < units.length; i++) {
                    if (resData[`${i}`]) {
                        units[i].id = Number(resData[`${i}`])
                    }

                    if (units[i].unitDetails) {
                        for (let j = 0; j < units[i].unitDetails.length; j++) {
                            if (resData[`${i}_${j}`]) {
                                units[i].unitDetails[j].id = Number(resData[`${i}_${j}`])
                            }
                        }
                    }
                }
                state.currentProperty.units = units
            }
            state.propertiesLoaders.updateUnitsLoader = false;
        },
        // get property report
        getPropertiesReportRequest(state) {
            state.isLoading = true
        },
        getPropertiesReportSuccess(state, action) {
            state.isLoading = false
            state.propertyReport = action.payload
        },
        getPropertiesReportFailure(state) {
            state.isLoading = false
        },

        // delete property
        deletePropertiesRequest(state) {
            state.isLoading = true
        },
        deletePropertiesSuccess(state, action) {
            state.isLoading = false
            const id = action.payload.payload.id;
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                state.panelView.data.splice(elementIndex, 1)
            }
            state.panelView.pagination.count = state.panelView.pagination.count - 1;
        },
        deletePropertiesFailure(state) {
            state.isLoading = false
        },

        //get properties comments
        getPropertiesCommentsRequest(state) {
            state.isLoading = true
            state.comments = []
        },
        getPropertiesCommentsSuccess(state, action) {
            state.isLoading = false
            state.comments = action.payload || []
        },
        getPropertiesCommentsFailure(state) {
            state.isLoading = false
            state.comments = []
        },

        //update properties comments
        updatePropertiesCommentsRequest(state) {
            state.isLoading = true
        },
        updatePropertiesCommentsSuccess(state) {
            state.isLoading = false
            state.currentComment = {
                message: ""
            }
        },
        updatePropertiesCommentsFailure(state) {
            state.isLoading = false
        },

        // update comment text
        updatePropertiesCurrentComment(state, action) {
            state.currentComment = {
                message: action.payload
            }
        },

        //delete properties comments
        deletePropertiesCommentRequest(state) {
            state.isLoading = true
        },
        deletePropertiesCommentSuccess(state, action) {
            state.isLoading = false
            const id = action.payload.payload.id;
            const elementIndex = state.comments.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                state.comments.splice(elementIndex, 1)
            }

        },
        deletePropertiesCommentFailure(state) {
            state.isLoading = false
        },

        //update properties view type
        updatePropertiesViewType(state, action) {
            state.viewType = action.payload
        },

        //download properties list
        downloadPropertiesRequest(state, action) {
            state.propertiesFileName = `properties_${moment().format("YYYY-MM-DD_h-mm-ssA")}.xlsx`
            state.isLoading = true
        },
        downloadPropertiesSuccess(state, action) {
            state.isLoading = false
            const blob = new Blob([action.payload.data], { type: defaultExcelType });
            saveAs(blob, state.propertiesFileName || "report.xlsx");
            state.propertiesFileName = ''
        },
        downloadPropertiesFailure(state) {
            state.isLoading = false
            state.propertiesFileName = ''
        },

        // update properties filter
        updatePropertiesFilter(state, action) {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value
            }
            state.clearfilter = false
        },

        // Revert filter to temporary filter values
        revertPropertiesFilter(state, action) {
            state.filter = {
                ...state.tmpfilter
            }
        },

        //update temporary filter
        updateTempPropertiesFilter(state, action) {
            state.tmpfilter = {
                ...state.filter
            }
        },

        clearPropertiesFilter(state) {
            state.filter = {
                managerFilter: [],
                propertiesFilter: [],
                flagFilter: [],
                unitTypeFilter: [],
                affordabilityLevelFilter: [],
                preferenceFilter: [],
                householdSizeFilter: [],
                startDate: '',
                endDate: '',
                minSqfeetFilter: '',
                maxSqfeetFilter: ''
            }
            state.clearfilter = true
        },

        updateUnitPhotos(state, action) {
            const { file, index, itemIndex } = action.payload;
            let unitsData = state.unitPhotos || {};
            if (unitsData[`${index}_${itemIndex}`]) {
                unitsData[`${index}_${itemIndex}`] = [...(unitsData[`${index}_${itemIndex}`]), ...file]
            } else {
                unitsData[`${index}_${itemIndex}`] = [...file]
            }
            state.unitPhotos = unitsData;
            state.isUnitsEdited = true
            state.hasUnitPhotosChanges = true
        },

        deleteUnitPhotos(state, action) {
            const { delIndex, index, itemIndex } = action.payload;
            let unitsData = state.unitPhotos || {};
            if (unitsData[`${index}_${itemIndex}`]) {
                unitsData[`${index}_${itemIndex}`].splice(delIndex, 1)
            } else {
                unitsData[`${index}_${itemIndex}`] = []
            }
            state.unitPhotos = unitsData;
            state.isUnitsEdited = true
            state.hasUnitPhotosChanges = true
        },

        updateUnitPhotosRequest(state) {
            state.propertiesLoaders.updateUnitPhotosLoader = true
        },
        updateUnitPhotosSuccess(state) {
            state.propertiesLoaders.updateUnitPhotosLoader = false
            state.isUnitsEdited = false
            state.hasUnitPhotosChanges = false
        },
        updateUnitPhotosFailure(state) {
            state.propertiesLoaders.updateUnitPhotosLoader = false
        },

        getPropertiesUnitsRequest(state) {
            state.propertiesLoaders.getUnitsLoader = true
        },
        getPropertiesUnitsSuccess(state, action) {
            let { units, unitDetails, unitPhotos } = action.payload;
            let tmpUnitPhotos = {}
            for (let i = 0; i < units.length; i++) {
                units[i].unitDetails = unitDetails.filter(f => (units[i].id === f.unit_id))
                units[i].accordion = i === 0 ? true : false
                for (let j = 0; j < units[i].unitDetails.length; j++) {
                    tmpUnitPhotos[`${i}_${j}`] = unitPhotos.filter(f => (f.unit_detail_id === units[i].unitDetails[j].id))
                }
            }

            state.currentProperty.units = units

            //For checking field update purpose
            state.propertyDBData.units = units

            state.unitPhotos = tmpUnitPhotos
            state.propertiesLoaders.getUnitsLoader = false
            state.isUnitsEdited = false
            state.hasUnitChanges = false
            state.hasUnitPhotosChanges = false
        },
        getPropertiesUnitsFailure(state) {
            state.propertiesLoaders.getUnitsLoader = false
        },

        getPropertiesPhotosRequest(state) {
            state.propertiesLoaders.getPhotosLoader = true
        },
        getPropertiesPhotosSuccess(state, action) {
            state.propertiesLoaders.getPhotosLoader = false
            state.currentProperty.photos = action.payload
        },
        getPropertiesPhotosFailure(state) {
            state.propertiesLoaders.getPhotosLoader = false
        },

        getPropertiesAmenitiesRequest(state) {
            state.propertiesLoaders.getAmenitiesLoader = true
        },
        getPropertiesAmenitiesSuccess(state, action) {
            state.propertiesLoaders.getAmenitiesLoader = false
            state.currentProperty.amenities = action.payload

            //For checking field update purpose
            state.propertyDBData.amenities = action.payload
        },
        getPropertiesAmenitiesFailure(state) {
            state.propertiesLoaders.getAmenitiesLoader = false
        },

        resetPropertyData(state) {
            state.isDetailEdited = false;
            state.isUnitsEdited = false;
            state.propertySubmit = false;
            state.unitsSubmit = false;
            state.unitPhotos = initialState.unitPhotos
            state.currentProperty = initialState.currentProperty
            state.hasGeneralChanges = false
            state.hasAmenityChanges = false
            state.hasPhotosChange = false
            state.hasUnitChanges = false
            state.hasUnitPhotosChanges = false
        },

        // update property status by citystaff
        updatePropertyStatusRequest(state) {
            state.propertiesLoaders.updateStatusLoader = true
        },
        updatePropertyStatusSuccess(state) {
            state.propertiesLoaders.updateStatusLoader = false
        },
        updatePropertyStatusFailure(state) {
            state.propertiesLoaders.updateStatusLoader = false
        },

        // update property unit status by citystaff
        updatePropertyUnitsStatusRequest(state) {
            state.propertiesLoaders.updateUnitsStatusLoader = true
        },
        updatePropertyUnitsStatusSuccess(state) {
            state.propertiesLoaders.updateUnitsStatusLoader = false
        },
        updatePropertyUnitsStatusFailure(state) {
            state.propertiesLoaders.updateUnitsStatusLoader = false
        },
        updateOverallPropertiesLoaders(state){
            state.overallPropertiesLoaders = Object.values(state.propertiesLoaders).some(loaderValue => loaderValue)
        },
        getPropertiesLogsRequest(state) {
            state.isLoading = true
            state.logs = initialState.logs
        },
        getPropertiesLogsSuccess(state, action) {
            state.isLoading = false
            state.logs = action.payload || []
        },
        getPropertiesLogsFailure(state) {
            state.isLoading = false
            state.logs = initialState.logs
        },
    }
})

export const {
    getWelcomePageRequest, getWelcomePageSuccess, getWelcomePageFailure,
    updateGeneral, updateAmenities, updatePhotos, deletePhotos, updateUnits, updateUnitCount,
    updateUnitDetails, deleteUnit, deleteUnitDetail, addNewUnit,addSubUnit,
    getPropertiesListRequest, getPropertiesListSuccess, getPropertiesListFailure,
    getPropertiesCountRequest, getPropertiesCountSuccess, getPropertiesCountFailure,
    updateGeneralRequest, updateGeneralSuccess, updateGeneralFailure,
    updateAmenitiesRequest, updateAmenitiesSuccess, updateAmenitiesFailure,
    updatePhotosRequest, updatePhotosSuccess, updatePhotosFailure,
    getPropertiesLeftPanelListRequest, getPropertiesLeftPanelListSuccess, getPropertiesLeftPanelListFailure,
    getPropertiesDetailRequest, getPropertiesDetailSuccess, getPropertiesDetailFailure,
    getPropertiesUnitsRequest, getPropertiesUnitsSuccess, getPropertiesUnitsFailure,
    updatePropertiesFlagRequest, updatePropertiesFlagSuccess, updatePropertiesFlagFailure,
    propertiesApprovalRequest, propertiesApprovalSuccess, propertiesApprovalFailure,
    updateUnitsRequest, updateUnitsSuccess, updateUnitsFailure,
    getPropertiesReportRequest, getPropertiesReportSuccess, getPropertiesReportFailure,
    getPropertiesCommentsRequest, getPropertiesCommentsSuccess, getPropertiesCommentsFailure,
    updatePropertiesCommentsRequest, updatePropertiesCommentsSuccess, updatePropertiesCommentsFailure,
    updatePropertiesCurrentComment,
    deletePropertiesCommentRequest, deletePropertiesCommentSuccess, deletePropertiesCommentFailure,
    deletePropertiesRequest, deletePropertiesSuccess, deletePropertiesFailure,
    updatePropertiesViewType,
    downloadPropertiesRequest, downloadPropertiesSuccess, downloadPropertiesFailure,
    updateUnitPhotos, deleteUnitPhotos,
    updatePropertiesFilter, clearPropertiesFilter, updateTempPropertiesFilter, revertPropertiesFilter,
    updateUnitPhotosRequest, updateUnitPhotosSuccess, updateUnitPhotosFailure,
    getPropertiesPhotosRequest, getPropertiesPhotosSuccess, getPropertiesPhotosFailure,
    getPropertiesAmenitiesRequest, getPropertiesAmenitiesSuccess, getPropertiesAmenitiesFailure,
    resetPropertyData,
    updatePropertyStatusRequest, updatePropertyStatusSuccess, updatePropertyStatusFailure,
    updatePropertyUnitsStatusRequest, updatePropertyUnitsStatusSuccess, updatePropertyUnitsStatusFailure,
    updateOverallPropertiesLoaders, getPropertiesLogsRequest, getPropertiesLogsSuccess, getPropertiesLogsFailure,
} = propertiesSlice.actions;
export default propertiesSlice.reducer;